import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { periods, timezones } from '../constants';
import _find from 'lodash/find';
import { deleteStandupStartAction, toggleActiveDispatch } from '../actions/StandupsActions';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';

export class Standup extends Component {
    constructor(props) {
        super(props);

        this.config = {
            theme: {
                monochrome: {
                    enabled: true,
                    color: '#00d0bf',
                    shadeTo: 'light',
                    shadeIntensity: 0.65,
                },
            },
            chart: {
                sparkline: {
                    enabled: true,
                },
            },
            title: {
                text: null,
                offsetX: 0,
                style: {
                    fontSize: '24px',
                    cssClass: 'apexcharts-yaxis-title',
                },
            },
            subtitle: {
                text: 'Reports (Past 30 Days)',
                offsetX: 0,
                style: {
                    fontSize: '14px',
                    cssClass: 'apexcharts-yaxis-title',
                },
            },
            stroke: {
                curve: 'straight',
                width: 1,
            },
            xaxis: {
                categories: [],
            },
            tooltip: {
                fixed: {
                    enabled: false,
                },
                x: {
                    show: true,
                },
                y: {
                    title: {
                        formatter: function (seriesName) {
                            return 'Reports Count: ';
                        },
                    },
                },
                marker: {
                    show: true,
                },
            },
        };
    }

    _getDaysInPastMonth() {
        const { reports } = this.props.standup;
        const firstDay = moment().subtract(1, 'months');
        const lastDay = moment();

        let days = [];
        let day = firstDay;

        while (day <= lastDay) {
            let report = _find(reports, { date_created_at: `${day.format('YYYY-MM-DD')}` });
            const reported = report ? report.reported : 0;
            days.push(reported);
            this.config.xaxis.categories.push(day.format('MMMM DD'));
            this.config.title.text += reported;
            day = day.clone().add(1, 'day');
        }

        return { data: [{ data: days }], config: this.config };
    }

    deleteStandup(id) {
        const { deleteStandupStartAction } = this.props;

        deleteStandupStartAction(id);
    }

    toggleActive(id) {
        const { toggleActiveDispatch } = this.props;

        toggleActiveDispatch(id);
    }

    render() {
        const { standup } = this.props;
        const period = _find(periods, { value: standup.period });
        const timezone = _find(timezones, { value: standup.timezone });
        const { data, config } = this._getDaysInPastMonth();

        return (
            <div className="single-standup has-background-white">
                <div className="columns">
                    <div className="column">
                        <Link to={`/standups/report/${standup.id}`}>
                            <h3 className="title is-4 is-vertical-center">
                                <span
                                    className={
                                        'toggle-active mr-2 ' +
                                        (!!standup.active ? 'has-text-primary' : 'has-text-danger')
                                    }
                                >
                                    <i className="fad fa-circle fa-sm "></i>
                                </span>
                                {standup.name}
                            </h3>
                        </Link>
                    </div>
                    <div className="column has-text-right-desktop has-text-right-tablet">
                        <span className="edit-icon mr-1">
                            <Link to={`/standups/report/${standup.id}`}>
                                <span className="icon">
                                    <i className="fad fa-file-chart-line fa-lg"></i>
                                </span>
                            </Link>
                        </span>
                        <span className="edit-icon mr-1">
                            <Link to={`/standups/create/${standup.id}`}>
                                <span className="icon">
                                    <i className="fad fa-pencil-alt fa-lg"></i>
                                </span>
                            </Link>
                        </span>
                        <span
                            onClick={() => this.deleteStandup(standup.id)}
                            className="delete-icon has-cursor-pointer has-text-primary"
                        >
                            <span className="icon">
                                <i className="fad fa-trash-alt fa-lg"></i>
                            </span>
                        </span>
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <p className="has-text-grey mb-1">
                            Scheduled {period ? period.label : ''} at {standup.schedule_time} on
                            Timezone: {timezone ? timezone.label : ''}
                        </p>
                        <div className="mb-1">
                            {standup.participants.map((participant) => (
                                <figure
                                    key={participant.id}
                                    className="image is-48x48 participants-image is-tooltip-top tooltip"
                                    data-tooltip={`${participant.name} @${participant.username}`}
                                >
                                    <img className="is-rounded" src={participant.avatar} alt="" />
                                </figure>
                            ))}
                        </div>
                        <div className="mb-1">
                            {standup.channels.map((channel) => (
                                <span key={channel.id} className="tag is-dark channel-tag">
                                    #{channel.name}
                                </span>
                            ))}
                        </div>
                        <p className="has-text-grey">{standup.questions_count} Questions</p>
                    </div>
                    <div className="column is-one-third">
                        <ReactApexChart
                            options={config}
                            series={data}
                            type="area"
                            height="150px"
                            width="100%"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            deleteStandupStartAction,
            toggleActiveDispatch,
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(Standup);

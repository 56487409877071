import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import config from '../config';

export const FETCH_STANDUPS = 'FETCH_STANDUPS';
export const DELETE_STANDUP_START = 'DELETE_STANDUP_START';
export const DELETE_STANDUP = 'DELETE_STANDUP';
export const ADD_NEW_STANDUP = 'ADD_NEW_STANDUP';
export const TOGGLE_ACTIVE = 'TOGGLE_ACTIVE';

export const fetchStandupsAction = (data) => ({
    type: FETCH_STANDUPS,
    payload: data,
});

export const deleteStandupStartAction = (data) => ({
    type: DELETE_STANDUP_START,
    payload: data,
});

export const deleteStandupAction = (data) => ({
    type: DELETE_STANDUP,
    payload: data,
});

export const addNewStandupAction = (data) => ({
    type: ADD_NEW_STANDUP,
    payload: data,
});

export const toggleActiveAction = (data) => ({
    type: TOGGLE_ACTIVE,
    payload: data,
});

// dispatch

export const fetchStandupsDispatch = (url) => async (dispatch) => {
    try {
        const res = await axios.get(url, { withCredentials: true });

        return dispatch(fetchStandupsAction(res.data));
    } catch (e) {
        NotificationManager.error('Oops! something went wrong', 'Error');
    }
};

export const deleteStandupDispatch = (id) => async (dispatch) => {
    try {
        await axios.post(
            config.api_url + `/api/standup/${id}/delete`,
            {},
            { withCredentials: true }
        );

        dispatch(deleteStandupAction(id));
    } catch (e) {
        NotificationManager.error('Oops! something went wrong', 'Error');
    }
};

export const toggleActiveDispatch = (id) => async (dispatch) => {
    try {
        await axios.post(
            config.api_url + `/api/standup/${id}/toggle-active`,
            {},
            { withCredentials: true }
        );

        dispatch(toggleActiveAction(id));
    } catch (e) {
        NotificationManager.error('Oops! something went wrong', 'Error');
    }
};

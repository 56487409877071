import axios from 'axios';
import config from '../config';

export const FETCH_TEMPLATE = 'FETCH_TEMPLATE';

export const fetchTemplateAction = (payload) => ({
    type: FETCH_TEMPLATE,
    payload,
});

export const fetchTemplateDispatch = () => async (dispatch) => {
    try {
        const res = await axios.get(config.api_url + '/api/template', { withCredentials: true });

        dispatch(fetchTemplateAction(res.data));
    } catch (e) {
        console.log('Error', e);
    }
};

const initialState = {
    data: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEMPLATE:
            return Object.assign({}, state, {
                data: action.payload,
            });
        default:
            return state;
    }
};

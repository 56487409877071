import React, { Component } from 'react';
import SaveStandupForm from './forms/SaveStandupForm';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    saveStandupDispatch,
    fetchStandupDispatch,
    saveStandupAction,
} from '../actions/StandupActions';
import SaveStandupLoader from './SaveStandupLoader';
import { reset } from 'redux-form';
import FooterSlim from './FooterSlim';
import { Helmet } from 'react-helmet';
import config from '../config';

export class SaveStandup extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderForm = this.renderForm.bind(this);
    }

    renderChange(id) {
        if (id) {
            this.props.fetchStandupDispatch(id);
        } else {
            this.props.reset('saveStandupForm');
            this.props.saveStandupAction({});
        }
    }

    componentDidMount() {
        this.renderChange(this.props.match.params.id);
    }

    componentDidUpdate(nextProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            this.renderChange(nextProps.match.params.id);
        }
    }

    async handleSubmit(values) {
        await this.props.saveStandupDispatch(
            values,
            this.props.history,
            this.props.location.pathname
        );
    }

    renderForm() {
        const { loading } = this.props.standup;

        if (loading) {
            return <SaveStandupLoader />;
        }

        return <SaveStandupForm onSubmit={this.handleSubmit} />;
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Save Standup - {config.siteName}</title>
                </Helmet>
                <section className="section">
                    <div className="container">{this.renderForm()}</div>
                </section>
                <FooterSlim />
            </div>
        );
    }
}

const mapStateToProps = ({ standup }) => ({ standup });

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            saveStandupDispatch,
            fetchStandupDispatch,
            saveStandupAction,
            reset,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SaveStandup);

import React from 'react';
import config from '../config';

export default () => (
    <a
        href={config.slack_full_app_install_url}
        className="button is-dark is-medium is-rounded has-text-weight-bold"
    >
        <span className="icon mr-1">
            <i className="fab fa-slack fa-lg"></i>
        </span>
        Add To Slack
    </a>
);

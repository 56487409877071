import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import config from '../config';

export const LOADING_USER = 'LOADING_USER';
export const LOADED_USER = 'LOADED_USER';
export const SAVE_USER = 'SAVE_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const saveUserAction = (data) => ({
    type: SAVE_USER,
    payload: data,
});

export const loadingUserAction = () => ({
    type: LOADING_USER,
});

export const loadedUserAction = () => ({
    type: LOADED_USER,
});

export const logoutUsersAction = () => ({
    type: LOGOUT_USER,
});

export const saveUserDispatch = () => async (dispatch) => {
    dispatch(loadingUserAction());
    try {
        await axios.get(config.api_url + '/sanctum/csrf-cookie', {
            withCredentials: true,
        });

        const res = await axios.get(config.api_url + '/api/me', { withCredentials: true });
        dispatch(saveUserAction(res.data));
    } catch (e) {
    } finally {
        dispatch(loadedUserAction());
    }
};

export const logoutUsersDispatch = (history) => async (dispatch) => {
    try {
        dispatch(logoutUsersAction());
        await axios.post(
            config.api_url + '/logout',
            {},
            {
                withCredentials: true,
            }
        );
        history.push('/');
    } catch (e) {
        console.log(e);
        NotificationManager.error('Oops! something went wrong', 'Error');
    }
};

import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import Standup from './Standup';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchStandupsDispatch } from '../actions/StandupsActions';
import StandupLoader from './StandupsLoader';
import NoStandups from './NoStandups';
import DeleteStandups from './DeleteStandup';
import FooterSlim from './FooterSlim';
import { Helmet } from 'react-helmet';

export class Standups extends Component {
    constructor(props) {
        super(props);

        this.loadMoreStandups = this.loadMoreStandups.bind(this);
        this._renderStandups = this._renderStandups.bind(this);
    }

    async loadMoreStandups() {
        const { fetchStandupsDispatch, standups } = this.props;

        await fetchStandupsDispatch(standups.url);
    }

    _renderStandups() {
        const { standups } = this.props;

        let items = standups.data.map((standup, index) => (
            <Standup standup={standup} key={index} />
        ));

        if (!standups.url && items.length === 0) {
            return <NoStandups />;
        }

        return items;
    }

    render() {
        const { standups } = this.props;

        return (
            <div>
                <Helmet>
                    <title>Standups - SimpleStandups by Remoteforce</title>
                </Helmet>
                <section className="section">
                    <div className="container">
                        <DeleteStandups />
                        <h1 className="title is-5">My Standups</h1>
                        <h2 className="subtitle has-text-grey is-6">
                            A list of standups for your team.
                        </h2>
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={this.loadMoreStandups}
                            hasMore={!!standups.url}
                            loader={<StandupLoader key={0} />}
                        >
                            {this._renderStandups()}
                        </InfiniteScroll>
                    </div>
                </section>
                <FooterSlim />
            </div>
        );
    }
}

const mapStateToProps = ({ standups, user }) => ({ standups, user });

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchStandupsDispatch,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Standups);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteStandupDispatch, deleteStandupStartAction } from '../actions/StandupsActions';
import classNames from 'classnames';

export class DeleteStandup extends Component {
    constructor(props) {
        super(props);

        this.deleteStandup = this.deleteStandup.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
    }

    deleteStandup() {
        const {
            deleteStandupDispatch,
            standups: { deleteStandupIndex },
        } = this.props;

        deleteStandupDispatch(deleteStandupIndex);
    }

    cancelDelete() {
        const { deleteStandupStartAction } = this.props;

        deleteStandupStartAction(null);
    }

    render() {
        const {
            standups: { deleteStandupIndex },
        } = this.props;

        return (
            <div className={classNames('modal', { 'is-active': deleteStandupIndex !== null })}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Delete Standup</p>
                        <button
                            className="delete"
                            onClick={this.cancelDelete}
                            aria-label="close"
                        ></button>
                    </header>
                    <section className="modal-card-body">
                        <p className="has-text-weight-bold">
                            Are you sure you want to delete this Standup?
                        </p>
                        <p>
                            You will not be able to undo this action. All data associated with this
                            meeting will be deleted.
                        </p>
                    </section>
                    <footer className="modal-card-foot">
                        <button
                            className="delete-standup button is-medium is-danger is-rounded has-text-weight-bold"
                            onClick={this.deleteStandup}
                        >
                            <span className="icon mr-1">
                                <i className="fad fa-ban"></i>
                            </span>
                            Delete Standup
                        </button>
                        <button
                            className="cancel-delete button is-medium is-rounded has-text-weight-bold"
                            onClick={this.cancelDelete}
                        >
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ standups }) => ({ standups });

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            deleteStandupDispatch,
            deleteStandupStartAction,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(DeleteStandup);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { saveUserDispatch } from '../../actions/AuthCompleteActions';
import smoothscroll from 'smoothscroll-polyfill';
import LegalSubNav from '../layout/LegalSubNav';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';
import config from '../../config';

smoothscroll.polyfill();

class Subprocessors extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Subprocessors - {config.siteName}</title>
                </Helmet>
                <section className="section">
                    <div className="container">
                        <LegalSubNav />

                        <div>
                            <h1 className="title is-4 has-text-primary mt-6 mb-4">Subprocessors</h1>
                            <p className="mb-4">Below is a list of Subprocessors we work with.</p>
                            <table className="table is-bordered is-fullwidth mt-4">
                                <thead className="has-background-light">
                                    <tr>
                                        <th width="50%">Subprocressor</th>
                                        <th width="50%">Purpose</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Amazon Web Services, Inc.</td>
                                        <td>Cloud infrastructure hosting</td>
                                    </tr>
                                    {/*<tr>*/}
                                    {/*    <td>*/}
                                    {/*        Encharge*/}
                                    {/*    </td>*/}
                                    {/*    <td>*/}
                                    {/*        Onboarding emails*/}
                                    {/*    </td>*/}
                                    {/*</tr>*/}
                                    <tr>
                                        <td>Google LLC</td>
                                        <td>Business analytics</td>
                                    </tr>
                                    <tr>
                                        <td>Help Scout</td>
                                        <td>Customer service</td>
                                    </tr>
                                    <tr>
                                        <td>Mailgun Technologies, Inc.</td>
                                        <td>Email transmission</td>
                                    </tr>
                                    <tr>
                                        <td>Slack Technologies, Inc.</td>
                                        <td>Real-time chat communications</td>
                                    </tr>
                                    <tr>
                                        <td>Slack Technologies, Inc.</td>
                                        <td>SSO</td>
                                    </tr>
                                    <tr>
                                        <td>Stripe, Inc.</td>
                                        <td>Payment processing</td>
                                    </tr>
                                </tbody>
                            </table>

                            <p className="has-text-grey-light has-text-weight-bold">
                                Last Updated: May 13, 2020
                            </p>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        );
    }
}

const mapStateToProps = ({ nav, user }) => ({ nav, user });

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            saveUserDispatch,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Subprocessors);

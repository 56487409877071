import {
    SAVE_STANDUP,
    LOADED_STANDUP,
    LOADING_STANDUP,
    COLOR_PICKER_OPEN_STANDUP,
} from '../actions/StandupActions';

const initialState = {
    loading: false,
    data: {},
    colorPickerOpenIndex: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SAVE_STANDUP:
            return Object.assign({}, state, {
                data: action.payload || {},
            });
        case LOADING_STANDUP:
            return Object.assign({}, state, {
                loading: true,
            });
        case LOADED_STANDUP:
            return Object.assign({}, state, {
                loading: false,
            });
        case COLOR_PICKER_OPEN_STANDUP:
            return Object.assign({}, state, {
                colorPickerOpenIndex: action.payload,
            });
        default:
            return state;
    }
};

import {
    FETCH_REPORTS,
    RESET_REPORTS,
    TOGGLE_EXPORT_MODAL_REPORTS,
} from '../actions/ReportsActions';

const initialState = {
    page: 1,
    hasMore: true,
    data: [],
    standup: null,
    exportedModalShow: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REPORTS:
            return Object.assign({}, state, {
                page: state.page + 1,
                hasMore: !!action.payload.reports.next_page_url,
                data: [...state.data, ...action.payload.reports.data],
                standup: action.payload.standup,
            });
        case RESET_REPORTS:
            return initialState;
        case TOGGLE_EXPORT_MODAL_REPORTS:
            return Object.assign({}, state, {
                exportedModalShow: !state.exportedModalShow,
            });
        default:
            return state;
    }
};

import React from 'react';
import { BlockPicker } from 'react-color';

export default ({ input, handleClick, displayColorPicker, handleClose, color, handleChange }) => (
    <span className="color-picker">
        <div className="swatch" onClick={handleClick}>
            <div className="color" style={{ backgroundColor: color }} />
        </div>
        {displayColorPicker ? (
            <div className="popover">
                <div className="cover" onClick={handleClose} />
                <BlockPicker {...input} color={color} onChange={handleChange} />
            </div>
        ) : null}
    </span>
);

import React from 'react';
import classNames from 'classnames';
import AsyncPaginate from 'react-select-async-paginate';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment/moment';

export const TextField = ({ input, label, placeholder, type, meta: { touched, error } }) => {
    const showError = touched && error;

    return (
        <div className="field">
            <label className="label">{label}</label>
            <div className="control">
                <input
                    {...input}
                    className={classNames('input', { 'is-danger': showError })}
                    type={type}
                    placeholder={placeholder}
                />
            </div>
            {showError && <p className="help is-danger">{error}</p>}
        </div>
    );
};

export const SingleCheckboxField = ({ input, label, meta: { touched, error } }) => {
    const showError = touched && error;

    return (
        <div className="custom-checkbox-wrapper mb-3">
            <span className="custom-checkbox">
                <input id={input.name} {...input} checked={input.value} type="checkbox" />
                <label htmlFor={input.name}>{label}</label>
            </span>
            <label htmlFor={input.name}>{label}</label>
            {showError && <p className="help is-danger">{error}</p>}
        </div>
    );
};

export const TextFieldWithoutLabel = ({
    input,
    label,
    placeholder,
    type,
    meta: { touched, error },
}) => {
    const showError = touched && error;

    return (
        <div className="field">
            <div className="control">
                <input
                    {...input}
                    className={classNames('input', { 'is-danger': showError })}
                    type={type}
                    placeholder={placeholder}
                />
            </div>
            {showError && <p className="help is-danger">{error}</p>}
        </div>
    );
};

export const AsyncMultiSelect = ({
    input,
    label,
    loadOptions,
    selectedFields,
    onSelected,
    meta: { touched, error },
}) => {
    const showError = touched && error;

    return (
        <div className="field">
            <label className="label">{label}</label>
            <div className="control">
                <AsyncPaginate
                    {...input}
                    debounceTimeout={275}
                    loadOptions={loadOptions}
                    isMulti
                    closeMenuOnSelect={true}
                    onBlur={() => input.onBlur(input.value)}
                />
            </div>
            {showError && <p className="help is-danger">{error}</p>}
        </div>
    );
};

export const SingleSelect = ({
    input,
    label,
    options,
    selectedFields,
    onSelected,
    meta: { touched, error },
}) => {
    const showError = touched && error;

    return (
        <div className="field">
            <label className="label">{label}</label>
            <div className="control">
                <Select {...input} options={options} onBlur={() => input.onBlur(input.value)} />
            </div>
            {showError && <p className="help is-danger">{error}</p>}
        </div>
    );
};

export const WeekInput = ({ input, label, options, meta: { touched, error } }) => {
    const showError = touched && error;

    return (
        <div>
            {options.map((option, index) => (
                <div className="checkbox days-checkbox" key={index}>
                    <input
                        id={`${input.name}[${index}]`}
                        type="checkbox"
                        name={`${input.name}[${index}]`}
                        value={option}
                        checked={input.value.indexOf(option) !== -1}
                        onChange={(event) => {
                            const newValue = [...input.value];
                            if (event.target.checked) {
                                newValue.push(option);
                            } else {
                                newValue.splice(newValue.indexOf(option), 1);
                            }

                            return input.onChange(newValue);
                        }}
                    />
                    <label htmlFor={`${input.name}[${index}]`}>{option}</label>
                </div>
            ))}
            {showError && <p className="help is-danger">{error}</p>}
        </div>
    );
};

export const StartDate = ({ input, endDate, meta: { touched, error } }) => {
    const startDate = input.value
        ? moment(input.value).toDate()
        : moment().subtract(30, 'd').toDate();
    let endD = endDate ? moment(endDate).toDate() : moment().toDate();

    return (
        <div className="field">
            <label className="label">Start Date</label>
            <div className="control">
                <DatePicker
                    className="input is-fullwidth"
                    {...input}
                    value={input.value ? moment(input.value).format('MM/DD/YYYY') : null}
                    selected={startDate}
                    selectsStart
                    startDate={startDate}
                    endDate={endD}
                />
            </div>
            {error && <p className="help is-danger">{error}</p>}
        </div>
    );
};

export const EndDate = ({ input, startDate, meta: { touched, error } }) => {
    const endDate = input.value ? moment(input.value).toDate() : moment().toDate();
    let startD = startDate ? moment(startDate).toDate() : moment().toDate();

    return (
        <div className="field">
            <label className="label">End Date</label>
            <div className="control">
                <DatePicker
                    className="input is-fullwidth"
                    {...input}
                    value={input.value ? moment(input.value).format('MM/DD/YYYY') : null}
                    selected={endDate}
                    selectsEnd
                    startDate={startD}
                    endDate={endDate}
                />
            </div>
            {error && <p className="help is-danger">{error}</p>}
        </div>
    );
};

export const CheckboxField = ({ input, label, meta: { touched, error } }) => {
    const showError = touched && error;

    return (
        <div>
            <label className="checkbox">
                <input id={input.name} {...input} checked={input.value} type="checkbox" />
                <span className="ml-1">{label}</span>
            </label>
            {showError && <p className="help is-danger">{error}</p>}
        </div>
    );
};

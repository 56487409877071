import { ERROR, HIDE_ERROR } from '../actions/ErrorActions';

const initialState = {
    title: '',
    description: '',
    show: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ERROR:
            return Object.assign(
                state,
                {},
                {
                    ...action.payload,
                    show: true,
                }
            );
        case HIDE_ERROR:
            return Object.assign(
                state,
                {},
                {
                    show: false,
                }
            );
        default:
            return state;
    }
};

import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import ReportsLoader from './ReportsLoader';
import Report from './Report';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchReportsDispatch, resetReportAction } from '../actions/ReportsActions';
import NoReports from './NoReports';
import { Link } from 'react-router-dom';
import _groupBy from 'lodash/groupBy';
import _keys from 'lodash/keys';
import moment from 'moment/moment';
import ExportReportsForm from './forms/ExportReportsForm';
import { exportReportsDispatch } from '../actions/ReportsActions';
import ExportedModal from './ExportedModal';
import FooterSlim from './FooterSlim';
import { Helmet } from 'react-helmet';
import config from '../config';

export class Reports extends Component {
    constructor(props) {
        super(props);

        this._loadMoreReports = this._loadMoreReports.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        this.props.resetReportAction();
    }

    async submit(value) {
        await this.props.exportReportsDispatch(this.props.match.params.id, value);
    }

    async _loadMoreReports() {
        return this.props.fetchReportsDispatch(this.props.match.params.id, this.props.reports.page);
    }

    _renderReports() {
        const { reports } = this.props;

        const groupBy = _groupBy(reports.data, (report) =>
            moment(report.created_at, 'YYYY-MM-DD HH:mm:ss').format('dddd, MMMM Do YYYY')
        );

        let items = _keys(groupBy).map((created_at, aIndex) => (
            <div key={aIndex}>
                <div className="common-date has-text-centered mb-3">
                    <span className="date-display">{created_at}</span>
                </div>
                <div>
                    {groupBy[created_at].map((report, index) => (
                        <Report report={report} key={index} />
                    ))}
                </div>
            </div>
        ));

        if (!reports.hasMore && items.length === 0) {
            return <NoReports />;
        }

        return items;
    }

    render() {
        const { reports } = this.props;

        return (
            <div>
                <Helmet>
                    <title>Reports - {config.siteName}</title>
                </Helmet>
                <section className="section">
                    <div className="container">
                        <div className="columns">
                            <div className="column">
                                {reports.standup && (
                                    <h1 className="title is-5">Reports for {reports.standup}</h1>
                                )}
                            </div>
                            <div className="column">
                                <nav
                                    className="breadcrumb has-arrow-separator is-right"
                                    aria-label="breadcrumbs"
                                >
                                    <ul>
                                        <li>
                                            <Link to="/standups">Standups</Link>
                                        </li>
                                        <li className="is-active">
                                            <a href="#!" aria-current="page">
                                                Reports for {reports.standup}
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>

                        <div className="container">
                            <div className="columns">
                                <div className="column">
                                    <InfiniteScroll
                                        pageStart={0}
                                        loadMore={this._loadMoreReports}
                                        hasMore={!!reports.hasMore}
                                        loader={<ReportsLoader key={0} />}
                                    >
                                        {this._renderReports()}
                                    </InfiniteScroll>
                                </div>
                                <div className="column is-one-quarter">
                                    <div className="export-header has-text-centered">
                                        <h4 className="title is-6">
                                            <span className="icon mr-1">
                                                <i className="fad fa-file-chart-line fa-lg"></i>
                                            </span>
                                            Export Report
                                        </h4>
                                    </div>
                                    <div className="export-form">
                                        <ExportReportsForm onSubmit={this.submit} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ExportedModal />
                    </div>
                </section>
                <FooterSlim />
            </div>
        );
    }
}

const mapStateToProps = ({ reports }) => ({ reports });

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchReportsDispatch,
            resetReportAction,
            exportReportsDispatch,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Reports);

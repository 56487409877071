import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class LegalSubNav extends Component {
    render() {
        const setParentAsActive = (node) => {
            if (node) {
                node.parentNode.className = node.className;
            }
        };
        return (
            <div>
                <div className="tabs">
                    <ul>
                        <li>
                            <NavLink
                                innerRef={setParentAsActive}
                                activeClassName="is-active"
                                to="/legal/privacy-policy"
                            >
                                <span>Privacy Policy</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                innerRef={setParentAsActive}
                                activeClassName="is-active"
                                to="/legal/cookie-policy"
                            >
                                <span>Cookie Policy</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                innerRef={setParentAsActive}
                                activeClassName="is-active"
                                to="/legal/terms-and-conditions"
                            >
                                <span>Terms and Conditions</span>
                            </NavLink>
                        </li>
                        {/*<li>*/}
                        {/*    <NavLink*/}
                        {/*        innerRef={setParentAsActive}*/}
                        {/*        activeClassName="is-active"*/}
                        {/*        to="/legal/data-processing-agreement"*/}
                        {/*    >*/}
                        {/*        <span>*/}
                        {/*            Data Processing Agreement*/}
                        {/*        </span>*/}
                        {/*    </NavLink>*/}
                        {/*</li>*/}
                        <li>
                            <NavLink
                                innerRef={setParentAsActive}
                                activeClassName="is-active"
                                to="/legal/subprocessors"
                            >
                                <span>Subprocessors</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default LegalSubNav;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { saveUserDispatch } from '../../actions/AuthCompleteActions';
import smoothscroll from 'smoothscroll-polyfill';
import LegalSubNav from '../layout/LegalSubNav';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';
import config from '../../config';

smoothscroll.polyfill();

class CookiePolicy extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Cookie Policy - {config.siteName}</title>
                </Helmet>
                <section className="section">
                    <div className="container">
                        <LegalSubNav />

                        <div className="content has-contract">
                            <h1 className="title is-4 has-text-primary mt-6 mb-4">Cookie Policy</h1>

                            <p>
                                We use cookies to help improve your experience of our websites
                                located at&nbsp;
                                <a href="https://www.remoteforce.com">
                                    https://www.remoteforce.com
                                </a>{' '}
                                or&nbsp;
                                <a href="https://simplestandups.com">https://simplestandups.com</a>.
                                This cookie policy is part of Remoteforce Inc.'s privacy policy, and
                                covers the use of cookies between your device and our site. We also
                                provide basic information on third-party services we may use, who
                                may also use cookies as part of their service, though they are not
                                covered by our policy.
                            </p>
                            <p>
                                If you don’t wish to accept cookies from us, you should instruct
                                your browser to refuse cookies from our websites located at&nbsp;
                                <a href="https://www.remoteforce.com">
                                    https://www.remoteforce.com
                                </a>{' '}
                                or&nbsp;
                                <a href="https://simplestandups.com">https://simplestandups.com</a>,
                                with the understanding that we may be unable to provide you with
                                some of your desired content and services.
                            </p>

                            <h2 className="title is-5 has-text-grey">What is a cookie?</h2>
                            <p>
                                A cookie is a small piece of data that a website stores on your
                                device when you visit, typically containing information about the
                                website itself, a unique identifier that allows the site to
                                recognize your web browser when you return, additional data that
                                serves the purpose of the cookie, and the lifespan of the cookie
                                itself.
                            </p>
                            <p>
                                Cookies are used to enable certain features (eg. logging in), to
                                track site usage (eg. analytics), to store your user settings (eg.
                                timezone, notification preferences), and to personalize your content
                                (eg. advertising, language).
                            </p>
                            <p>
                                Cookies set by the website you are visiting are normally referred to
                                as “first-party cookies”, and typically only track your activity on
                                that particular site. Cookies set by other sites and companies (ie.
                                third parties) are called “third-party cookies”, and can be used to
                                track you on other websites that use the same third-party service.
                            </p>

                            <h2 className="title is-5 has-text-grey">
                                Types of cookies and how we use them
                            </h2>

                            <h3 className="title is-6">Essential cookies</h3>
                            <p>
                                Essential cookies are crucial to your experience of a website,
                                enabling core features like user logins, account management,
                                shopping carts and payment processing. We use essential cookies to
                                enable certain functions on our website.
                            </p>

                            <h3 className="title is-6">Performance cookies</h3>
                            <p>
                                Performance cookies are used in the tracking of how you use a
                                website during your visit, without collecting personal information
                                about you. Typically, this information is anonymous and aggregated
                                with information tracked across all site users, to help companies
                                understand visitor usage patterns, identify and diagnose problems or
                                errors their users may encounter, and make better strategic
                                decisions in improving their audience’s overall website experience.
                                These cookies may be set by the website you’re visiting
                                (first-party) or by third-party services. We use performance cookies
                                on our site.
                            </p>

                            <h3 className="title is-6">Functionality cookies</h3>
                            <p>
                                Functionality cookies are used in collecting information about your
                                device and any settings you may configure on the website you’re
                                visiting (like language and time zone settings). With this
                                information, websites can provide you with customized, enhanced or
                                optimized content and services. These cookies may be set by the
                                website you’re visiting (first-party) or by third-party service. We
                                use functionality cookies for selected features on our site.
                            </p>
                            <h3 className="title is-6">Targeting/advertising cookies</h3>
                            <p>
                                Targeting/advertising cookies are used in determining what
                                promotional content is more relevant and appropriate to you and your
                                interests. Websites may use them to deliver targeted advertising or
                                to limit the number of times you see an advertisement. This helps
                                companies improve the effectiveness of their campaigns and the
                                quality of content presented to you. These cookies may be set by the
                                website you’re visiting (first-party) or by third-party services.
                                Targeting/advertising cookies set by third-parties may be used to
                                track you on other websites that use the same third-party service.
                                We use targeting/advertising cookies on our site.
                            </p>

                            <h2 className="title is-5 has-text-grey">
                                Third-party cookies on our site
                            </h2>
                            <p>
                                We may employ third-party companies and individuals on our
                                websites—for example, analytics providers and content partners. We
                                grant these third parties access to selected information to perform
                                specific tasks on our behalf. They may also set third-party cookies
                                in order to deliver the services they are providing. Third-party
                                cookies can be used to track you on other websites that use the same
                                third-party service. As we have no control over third-party cookies,
                                they are not covered by Remoteforce Inc.'s cookie policy.
                            </p>

                            <h3 className="title is-6">Our third-party privacy promise</h3>
                            <p>
                                We review the privacy policies of all our third-party providers
                                before enlisting their services to ensure their practices align with
                                ours. We will never knowingly include third-party services that
                                compromise or violate the privacy of our users.
                            </p>

                            <h2 className="title is-5 has-text-grey">
                                How you can control or opt out of cookies
                            </h2>
                            <p>
                                If you do not wish to accept cookies from us, you can instruct your
                                browser to refuse cookies from our website. Most browsers are
                                configured to accept cookies by default, but you can update these
                                settings to either refuse cookies altogether, or to notify you when
                                a website is trying to set or update a cookie.
                            </p>
                            <p>
                                If you browse websites from multiple devices, you may need to update
                                your settings on each individual device.
                            </p>
                            <p>
                                Although some cookies can be blocked with little impact on your
                                experience of a website, blocking all cookies may mean you are
                                unable to access certain features and content across the sites you
                                visit.
                            </p>

                            <hr />
                            <p className="has-text-grey-light has-text-weight-bold">
                                Last Updated: May 13, 2020
                            </p>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        );
    }
}

const mapStateToProps = ({ nav, user }) => ({ nav, user });

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            saveUserDispatch,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CookiePolicy);

import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import config from '../config';
import logoIconInverse from '../images/remoteforce--logo-circle@2x.png';

export default (props) => (
    <section className="has-background-dark pt-4">
        <div className="container">
            <div className="section">
                <div className="columns">
                    <div className="column">
                        <div className="text-center">
                            <img src={logoIconInverse} alt="Remoteforce" width={50} />
                            <h3 className="title is-5 has-text-white mt-0 mb-2">
                                Simple Standups by Remoteforce is a Slack Bot for Scheduling
                                Asynchronous Standups Among Teams.
                            </h3>
                            <p className="my-2">
                                <a href={config.slack_full_app_install_url} className="footer-icon">
                                    <span className="icon has-text-white">
                                        <i className="fab fa-slack fa-lg"></i>
                                    </span>
                                </a>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://fb.me/simplestandups"
                                    className="footer-icon"
                                >
                                    <span className="icon has-text-white">
                                        <i className="fab fa-facebook fa-lg"></i>
                                    </span>
                                </a>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://m.me/simplestandups"
                                    className="footer-icon"
                                >
                                    <span className="icon has-text-white">
                                        <i className="fab fa-facebook-messenger fa-lg"></i>
                                    </span>
                                </a>
                            </p>
                            <p className="is-text has-text-white secondary">
                                Simple Standups by Remoteforce.
                                <br />
                                &copy; 2020{' '}
                                <a
                                    href="http://remoteforce.com"
                                    className="has-text-white has-text-weight-bold"
                                >
                                    Remoteforce Inc.
                                </a>{' '}
                                All rights reserved.
                            </p>
                        </div>
                    </div>
                    <div className="column pt-6">
                        <div className="columns">
                            <div className="column">&nbsp;</div>
                            <div className="column">
                                <div className="link">
                                    <HashLink to="/#features">Features</HashLink>
                                </div>
                                <div className="link">
                                    <HashLink to="/#pricing">Pricing</HashLink>
                                </div>
                                <div className="link">
                                    <HashLink to="/#faq">F.A.Q</HashLink>
                                </div>
                                <div className="link">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="mailto:support@remoteforce.com"
                                    >
                                        Contact Us
                                    </a>
                                </div>
                            </div>
                            <div className="column">
                                <div className="link">
                                    <Link to="/legal/privacy-policy">Privacy Policy</Link>
                                </div>
                                <div className="link">
                                    <Link to="/legal/cookie-policy">Cookie Policy</Link>
                                </div>
                                <div className="link">
                                    <Link to="/legal/terms-and-conditions">
                                        Terms and Conditions
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

import {
    FETCH_STANDUPS,
    DELETE_STANDUP_START,
    DELETE_STANDUP,
    ADD_NEW_STANDUP,
    TOGGLE_ACTIVE,
} from '../actions/StandupsActions';
import _remove from 'lodash/remove';
import _findIndex from 'lodash/findIndex';
import _update from 'lodash/update';
import config from '../config';

const initialState = {
    url: config.api_url + '/api/standup',
    data: [],
    deleteStandupIndex: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_STANDUPS:
            return Object.assign({}, state, {
                url: action.payload.next_page_url,
                data: [...state.data, ...action.payload.data],
            });
        case DELETE_STANDUP_START:
            return Object.assign({}, state, {
                deleteStandupIndex: action.payload,
            });
        case DELETE_STANDUP:
            return Object.assign({}, state, {
                deleteStandupIndex: null,
                data: _remove(state.data, (standup) => standup.id !== action.payload),
            });
        case ADD_NEW_STANDUP:
            const presentIndex = _findIndex(state.data, { id: action.payload.id });

            if (presentIndex >= 0) {
                const newObj1 = Object.assign({}, state);

                newObj1.data[presentIndex] = {
                    ...newObj1.data[presentIndex],
                    ...action.payload,
                };

                return newObj1;
            }

            return Object.assign({}, state, {
                data: [action.payload, ...state.data],
            });
        case TOGGLE_ACTIVE:
            const toggleIndex = _findIndex(state.data, { id: action.payload });

            const newObj2 = Object.assign({}, state);

            return _update(newObj2, `data[${toggleIndex}.active]`, (active) => !active);

        default:
            return state;
    }
};

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ScrollToTop from './components/ScrollToTop';
import Main from './components/Main';
import Home from './components/Home';
import PrivacyPolicy from './components/legal/PrivacyPolicy';
import CookiePolicy from './components/legal/CookiePolicy';
import TermsAndConditions from './components/legal/TermsAndConditions';
// import DataProcessingAgreement from './components/legal/DataProcessingAgreement';
import Subprocessors from './components/legal/Subprocessors';
import Error from './components/Error';
import Layout from './components/layout';
import config from './config';

export default class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Helmet>
                    <title>
                        {config.siteName} - #1 Bot for Asynchronous Standup Meetings on Slack
                    </title>
                </Helmet>
                <BrowserRouter>
                    <Layout>
                        <ScrollToTop>
                            <Switch>
                                <Route component={Main} path="/standups" />
                                <Route component={Home} path="/" exact />
                                <Route component={PrivacyPolicy} path="/privacy" exact />
                                <Route
                                    component={PrivacyPolicy}
                                    path="/legal/privacy-policy"
                                    exact
                                />
                                <Route component={CookiePolicy} path="/legal/cookie-policy" exact />
                                <Route
                                    component={TermsAndConditions}
                                    path="/legal/terms-and-conditions"
                                    exact
                                />
                                {/*<Route component={DataProcessingAgreement} path="/legal/data-processing-agreement" exact />*/}
                                <Route
                                    component={Subprocessors}
                                    path="/legal/subprocessors"
                                    exact
                                />
                                <Route component={Error} path="/404" />
                                <Redirect to="/404" />
                            </Switch>
                        </ScrollToTop>
                    </Layout>
                </BrowserRouter>
            </Provider>
        );
    }
}

import { LOGOUT_USER, LOADING_USER, LOADED_USER, SAVE_USER } from '../actions/AuthCompleteActions';

const initialState = {
    loading: true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SAVE_USER:
            return Object.assign({}, state, {
                ...action.payload,
            });
        case LOADING_USER:
            return Object.assign({}, state, {
                loading: true,
            });
        case LOADED_USER:
            return Object.assign({}, state, {
                loading: false,
            });
        case LOGOUT_USER:
            return initialState;
        default:
            return state;
    }
};

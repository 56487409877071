import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { saveUserDispatch } from '../actions/AuthCompleteActions';
import Footer from './Footer';
import errorImg from '../images/error.png';

class Error extends Component {
    render() {
        return (
            <div>
                <section className="section is-medium">
                    <div className="container">
                        <div className="columns is-vcentered">
                            <div className="column has-text-centered">
                                <h1 className="title">Page Not Found</h1>
                                <p className="subtitle">
                                    Oops! We can't seem to find the page you are looking for.
                                </p>
                            </div>
                            <div className="column has-text-centered">
                                <img src={errorImg} alt="Page not found" width="300" />
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = ({ nav, user }) => ({ nav, user });

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            saveUserDispatch,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Error);

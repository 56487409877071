import React from 'react';
import { Link } from 'react-router-dom';

export default () => (
    <section className="section plan-card plan-card--subtle has-background-grey-lighter">
        <header className="bd-index-header">
            <h4 className="title is-4 has-text-centered">
                No Reports Were Captured for this Standup.
            </h4>
        </header>

        <div className="hero-buttons">
            <Link
                to="/standups"
                className="button is-primary is-rounded is-medium has-text-weight-bold"
            >
                <span className="icon mr-1">
                    <i className="fad fa-arrow-circle-left"></i>
                </span>
                <span>Back to Standups</span>
            </Link>
        </div>
    </section>
);

import React, { Component } from 'react';

class ReportResponse extends Component {
    render() {
        const { response, questionNo } = this.props;

        return (
            <div className="response mt-3">
                <h4 className="title is-6 mb-1">
                    #{questionNo}. {response.question}
                </h4>
                <i>{response.description}</i>
                <div
                    style={{ borderLeft: `5px solid ${response.color}` }}
                    className="response-answer"
                >
                    {response.answer}
                </div>
            </div>
        );
    }
}

export default ReportResponse;

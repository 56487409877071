import React, { Component } from 'react';
import { Field, change, formValueSelector } from 'redux-form';
import ColorPicker from './ColorPicker';
import { TextFieldWithoutLabel } from './forms/fields';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { colorPickerOpenIndexAction } from '../actions/StandupActions';

const SortableItem = SortableElement(({ children }) => children);

const DragHandle = SortableHandle(() => (
    <span className="drag-handle icon has-text-grey mr-2">
        <i className="fas fa-grip-horizontal fa-lg"></i>
    </span>
));

export class Quest extends Component {
    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
    }

    handleClick(index) {
        this.props.colorPickerOpenIndexAction(index);
    }

    handleClose() {
        this.props.colorPickerOpenIndexAction(null);
    }

    handleChange(color, name) {
        this.props.change('saveStandupForm', name, color.hex);
    }

    render() {
        const {
            fields,
            meta: { touched, error },
            state,
        } = this.props;

        const { colorPickerOpenIndex } = state.standup;

        const selector = formValueSelector('saveStandupForm');

        return (
            <div className="questions">
                <label className="label">Questions To Ask Participants</label>
                {fields.map((question, index) => (
                    <SortableItem index={index} key={index}>
                        <div className="field question has-background-white plan-card plan-card--subtle">
                            <label className="label">
                                <DragHandle />
                                Question #{index + 1}
                                <Field
                                    component={ColorPicker}
                                    name={`${question}.color`}
                                    handleClick={() => this.handleClick(index)}
                                    displayColorPicker={colorPickerOpenIndex === index}
                                    handleClose={this.handleClose}
                                    color={selector(state, `${question}.color`) || '#cccccc'}
                                    handleChange={(color) =>
                                        this.handleChange(color, `${question}.color`)
                                    }
                                />
                            </label>
                            <div className="control">
                                <Field
                                    component={TextFieldWithoutLabel}
                                    name={`${question}.question`}
                                    placeholder="Question: Is there anything blocking progress?"
                                />
                                <Field
                                    component={TextFieldWithoutLabel}
                                    name={`${question}.description`}
                                    type="text"
                                    placeholder="Description (Optional): Make sure you checkout internal wiki for general problems"
                                />
                            </div>
                            <span
                                onClick={() => fields.remove(index)}
                                className="delete-question icon has-text-grey"
                            >
                                <i className="fad fa-trash-alt fa-lg delete-trash"></i>
                            </span>
                        </div>
                    </SortableItem>
                ))}
                {touched && error && <p className="help is-danger">{error}</p>}
                <div className="columns">
                    <div className="column has-text-centered">
                        <div className="add-question" onClick={() => fields.push()}>
                            <span className="icon has-text-grey mr-1">
                                <i className="fad fa-comment-alt-plus fa-lg"></i>
                            </span>
                            Add Question
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    state,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            change,
            colorPickerOpenIndexAction,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Quest);

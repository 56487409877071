import React, { Component } from 'react';
import ReportResponse from './ReportResponse';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

class Report extends Component {
    render() {
        const {
            report: { responses, user, created_at },
        } = this.props;

        const time = moment(created_at, 'YYYY-MM-DD HH:mm:ss')
            .utc(true)
            .tz(this.props.user.timezone)
            .format('MM-DD-YYYY hh:mm A');

        return (
            <div>
                <div className="single-report">
                    <div className="is-vertical-center">
                        <figure className="image is-48x48">
                            <img className="is-rounded" src={user.avatar} alt="" />
                        </figure>
                        <div>
                            <div>
                                <span className="title is-6 ml-2">
                                    {user.name} (@{user.username})
                                </span>
                            </div>
                            <div>
                                <span className="ml-2 has-text-grey-light">{time}</span>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        {responses.map((response, key) => (
                            <ReportResponse questionNo={key + 1} response={response} key={key} />
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(Report);

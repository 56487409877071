import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Standups from './Standups';
import SaveStandup from './SaveStandup';
import Reports from './Reports';
import { NotificationContainer } from 'react-notifications';
import { saveUserDispatch } from '../actions/AuthCompleteActions';
import StandupLoader from './StandupsLoader';

class Main extends Component {
    componentDidMount() {
        const { user } = this.props;

        if (!user.id && !user.loaded) {
            this.props.saveUserDispatch();
        }
    }

    render() {
        const { user } = this.props;

        if (user.loading) {
            return (
                <section className="section">
                    <div className="container">
                        <StandupLoader key={0} />
                    </div>
                </section>
            );
        }

        if (!this.props.user.id) {
            return <Redirect to="/" />;
        }

        return (
            <div>
                <Switch>
                    <Route component={Standups} path="/standups" exact />
                    <Route component={SaveStandup} path="/standups/create/:id?" exact />
                    <Route component={Reports} path="/standups/report/:id" exact />
                </Switch>
                <NotificationContainer />
            </div>
        );
    }
}

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            saveUserDispatch,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Main);

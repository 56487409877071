import { TOGGLE_UNSUBSCRIBE_BILLING } from '../actions/BillingActions';

const initialState = {
    showUnsubscribeModal: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_UNSUBSCRIBE_BILLING:
            return Object.assign({}, state, {
                showUnsubscribeModal: !state.showUnsubscribeModal,
            });
        default:
            return state;
    }
};

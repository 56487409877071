import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleExportModalReportAction } from '../actions/ReportsActions';
import classNames from 'classnames';

export class ExportedModal extends Component {
    constructor(props) {
        super(props);

        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.props.toggleExportModalReportAction();
    }

    render() {
        const { exportedModalShow } = this.props.reports;

        return (
            <div className={classNames('modal', { 'is-active': exportedModalShow })}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Export Report in Progress</p>
                        <button
                            className="delete close-modal"
                            onClick={this.closeModal}
                            aria-label="close"
                        ></button>
                    </header>
                    <section className="modal-card-body">
                        <p className="has-text-weight-bold">Your report is being generated.</p>
                        <p>Once complete, your report will be emailed to you.</p>
                    </section>
                    <footer className="modal-card-foot">
                        <button
                            onClick={this.closeModal}
                            className="button is-medium is-rounded has-text-weight-bold"
                        >
                            Okay
                        </button>
                    </footer>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ reports }) => ({ reports });

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            toggleExportModalReportAction,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ExportedModal);

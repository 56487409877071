import { TOGGLE_NAV } from '../actions/NavActions';

const initialState = {
    isOpen: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_NAV:
            return Object.assign({}, state, {
                isOpen: !state.isOpen,
            });
        default:
            return state;
    }
};

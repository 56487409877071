import React from 'react';
import logoRemoteforce from '../images/remoteforce--logo-circle@2x.png';

export default (props) => (
    <section className="section has-background-dark">
        <div className="container has-text-centered">
            <a href="http://remoteforce.com">
                <img src={logoRemoteforce} width={50} alt={'Remoteforce'} />
            </a>
            <p className="is-text has-text-white is-size-6">
                Simple Standups by Remoteforce.
                <br />
                &copy; 2020
                <a
                    href="http://remoteforce.com"
                    className="has-text-white has-text-weight-bold mx-1"
                >
                    Remoteforce Inc.
                </a>
                All rights reserved.
            </p>
        </div>
    </section>
);

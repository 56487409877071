import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { saveUserDispatch } from '../../actions/AuthCompleteActions';
import smoothscroll from 'smoothscroll-polyfill';
import LegalSubNav from '../layout/LegalSubNav';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';
import config from '../../config';

smoothscroll.polyfill();

class PrivacyPolicy extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Privacy Policy - {config.siteName}</title>
                </Helmet>
                <section className="section">
                    <div className="container">
                        <LegalSubNav />

                        <div className="content has-contract">
                            <h1 className="title is-4 has-text-primary mt-6 mb-4">
                                Privacy Policy
                            </h1>

                            <h2 className="title is-5 has-text-grey">Introduction</h2>
                            <p>
                                Remoteforce Inc. ("
                                <strong>
                                    <em>Company</em>
                                </strong>
                                " or "
                                <strong>
                                    <em>We</em>
                                </strong>
                                ") respect your privacy and are committed to protecting it through
                                our compliance with this policy.
                            </p>
                            <p>
                                This policy describes the types of information we may collect from
                                you or that you may provide when you visit our websites located
                                at&nbsp;
                                <a href="https://www.remoteforce.com">
                                    https://www.remoteforce.com
                                </a>{' '}
                                or{' '}
                                <a href="https://simplestandups.com">https://simplestandups.com</a>{' '}
                                or when you use our Remoteforce or SimpleStandups online software
                                (collectively, the websites and our software products are the "
                                <strong>
                                    <em>Services"</em>
                                </strong>
                                ) and our practices for collecting, using, maintaining, protecting,
                                and disclosing that information.
                            </p>
                            <p>This policy applies to information we collect:</p>
                            <ul className="has-list-checkmarks">
                                <li>Via the Services.</li>
                                <li>
                                    In email, text, and other electronic messages between you and
                                    the Services.
                                </li>
                                <li>
                                    When you interact with our advertising and applications on
                                    third-party websites and services, if those applications or
                                    advertising include links to this policy.
                                </li>
                            </ul>
                            <p>It does not apply to information collected by:</p>
                            <ul className="has-list-crosses">
                                <li>
                                    Us offline or through any other means, including on any other
                                    website operated by Company or any third party (including our
                                    affiliates and subsidiaries); or
                                </li>
                                <li>
                                    Any third party (including our affiliates and subsidiaries),
                                    including through any application or content (including
                                    advertising) that may link to or be accessible from or on the
                                    Services.
                                </li>
                            </ul>
                            <p>
                                Please read this policy carefully to understand our policies and
                                practices regarding your information and how we will treat it. If
                                you do not agree with our policies and practices, your choice is not
                                to use our Website. By accessing or using this Website, you agree to
                                this privacy policy. This policy may change from time to time (see
                                Changes to Our Privacy Policy). Your continued use of this Website
                                after we make changes is deemed to be acceptance of those changes,
                                so please check the policy periodically for updates.
                            </p>

                            <h2 className="title is-5 has-text-grey">
                                Children Under the Age of 18
                            </h2>
                            <p>
                                Our Services are not intended for use by anyone under 18 years of
                                age. No one under age 18 may provide any information to or on the
                                Services. We do not knowingly collect personal information from
                                anyone under 18. If you are under 18, do not use or provide any
                                information on our Services or through any of its features, register
                                on the Services, make any purchases through the Services, or provide
                                any information about yourself to us, including your name, address,
                                telephone number, email address, or any screen name or user name you
                                may use. If we learn we have collected or received personal
                                information from anyone under 18 without verification of parental
                                consent, we will delete that information. If you believe we might
                                have any information from or about anyone under 18, please contact
                                us at&nbsp;
                                <a href="mailto:support@remoteforce.com">support@remoteforce.com</a>
                                .
                            </p>

                            <h2 className="title is-5 has-text-grey">
                                Information We Collect About You and How We Collect It
                            </h2>
                            <p>
                                We collect several types of information from and about users of our
                                Services, including information:
                            </p>
                            <ul className="has-list-checkmarks">
                                <li>
                                    By which you may be personally identified, such as name, name,
                                    alias, online identifiers (e.g. social media handles), IP
                                    addresses, account name, postal address, street address, email
                                    address, telephone number, organization/team name, and ("
                                    <strong>
                                        <em>personal information</em>
                                    </strong>
                                    "); and
                                </li>
                                <li>
                                    Internet or other electronic network activity information,
                                    including, but not limited to, browsing history, search history,
                                    and information regarding your interaction with an internet
                                    website, application, or advertisement Internet or other
                                    electronic network activity information, including, but not
                                    limited to, browsing history, search history, and information
                                    regarding your interaction with an internet website,
                                    application, or advertisement.
                                </li>
                            </ul>
                            <p>We collect this information:</p>
                            <ul className="has-list-checkmarks">
                                <li>Directly from you when you provide it to us.</li>
                                <li>
                                    Automatically as you navigate through the site. Information
                                    collected automatically may include usage details, IP addresses,
                                    and information collected through cookies and web beacons.
                                </li>
                                <li>
                                    From third parties, for example, from business partners such as
                                    Google, HelpScout, Microsoft, Slack, and Stripe.
                                </li>
                            </ul>

                            <h3 className="title is-6">Information You Provide to Us</h3>
                            <p>
                                The information we collect on or through our Services may include:
                            </p>
                            <ul className="has-list-checkmarks">
                                <li>
                                    Information that you provide by filling in forms on our
                                    Services. This includes information provided at the time of
                                    registering to use our Services, subscribing to our Services, or
                                    requesting further Services. We may also ask you for information
                                    when you enter a contest or promotion sponsored by us, and when
                                    you report a problem with our Services.
                                </li>
                                <li>
                                    Records and copies of your correspondence (including email
                                    addresses), if you contact us.
                                </li>
                                <li>
                                    Your responses to surveys that we might ask you to complete for
                                    research purposes.
                                </li>
                                <li>
                                    Details of transactions you carry out through our Services and
                                    of the fulfillment of your orders. You may be required to
                                    provide financial information via one of our third-party payment
                                    processors before placing an order through our Services.
                                </li>
                            </ul>

                            <h3 className="title is-6">
                                Information We Collect Through Automatic Data Collection
                                Technologies
                            </h3>
                            <p>
                                As you navigate through and interact with our Services, we may use
                                automatic data collection technologies to collect certain
                                information about your equipment, browsing actions, and patterns,
                                including:
                            </p>
                            <ul className="has-list-checkmarks">
                                <li>
                                    Details of your visits to our Services, including location data,
                                    logs, and other communication data and the resources that you
                                    access and use on the Services.
                                </li>
                                <li>
                                    Information about your computer and internet connection,
                                    including your IP address, operating system, and browser type.
                                </li>
                            </ul>
                            <p>
                                We also may use these technologies to collect information about your
                                online activities over time and across third-party Services or other
                                online services (behavioral tracking). Email us at{' '}
                                <a href="mailto:support@remoteforce.com">support@remoteforce.com</a>{' '}
                                for information on how you can opt out of behavioral tracking and
                                how we respond to web browser signals and other mechanisms that
                                enable consumers to exercise choice about behavioral tracking.
                            </p>
                            <p>
                                The information we collect automatically may include personal
                                information. It helps us to improve our Services and to deliver a
                                better and more personalized service, including by enabling us to:
                            </p>
                            <ul className="has-list-checkmarks">
                                <li>Estimate our audience size and usage patterns.</li>
                                <li>
                                    Store information about your preferences, allowing us to
                                    customize our Services according to your individual interests.
                                </li>
                                <li>Speed up your searches.</li>
                                <li>Recognize you when you return to our Services.</li>
                            </ul>
                            <p>
                                The technologies we use for this automatic data collection may
                                include:
                            </p>
                            <ul className="has-list-checkmarks">
                                <li>
                                    Cookies (or browser cookies). A cookie is a small file placed on
                                    the hard drive of your computer. You may refuse to accept
                                    browser cookies by activating the appropriate setting on your
                                    browser. However, if you select this setting you may be unable
                                    to access certain parts of our Services. Unless you have
                                    adjusted your browser setting so that it will refuse cookies,
                                    our system will issue cookies when you direct your browser to
                                    our Services.
                                </li>
                                <li>
                                    Flash Cookies. Certain features of our Services may use local
                                    stored objects (or Flash cookies) to collect and store
                                    information about your preferences and navigation to, from, and
                                    on our Services. Flash cookies are not managed by the same
                                    browser settings as are used for browser cookies. For
                                    information about managing your privacy and security settings
                                    for Flash cookies, see Choices About How We Use and Disclose
                                    Your Information.
                                </li>
                                <li>
                                    Web Beacons. Parts of our the Services may contain small
                                    electronic files known as web beacons (also referred to as clear
                                    gifs, pixel tags, and single-pixel gifs) that permit the
                                    Company, for example, to count users who have used the Services
                                    and for other related Services statistics (for example,
                                    recording the popularity of certain Services content and
                                    verifying system and server integrity).
                                </li>
                            </ul>

                            <h3 className="title is-6">
                                Third-Party Use of Cookies and Other Tracking Technologies
                            </h3>
                            <p>
                                Some content or applications, including advertisements, on the
                                Services are served by third-parties, including advertisers, ad
                                networks and servers, content providers, and application providers.
                                These third parties may use cookies alone or in conjunction with web
                                beacons or other tracking technologies to collect information about
                                you when you use our Services. The information they collect may be
                                associated with your personal information or they may collect
                                information, including personal information, about your online
                                activities over time and across different Services and other online
                                services. They may use this information to provide you with
                                interest-based (behavioral) advertising or other targeted content.
                            </p>
                            <p>
                                We do not control these third parties' tracking technologies or how
                                they may be used. If you have any questions about an advertisement
                                or other targeted content, you should contact the responsible
                                provider directly. For information about how you can opt out of
                                receiving targeted advertising from many providers, see Choices
                                About How We Use and Disclose Your Information.
                            </p>

                            <h2 className="title is-5 has-text-grey">
                                How We Use Your Information
                            </h2>
                            <p>
                                We use information that we collect about you or that you provide to
                                us, including any personal information:
                            </p>
                            <ul className="has-list-checkmarks">
                                <li>To present our Services and its contents to you.</li>
                                <li>
                                    To provide you with information, products, or services that you
                                    request from us.
                                </li>
                                <li>To fulfill any other purpose for which you provide it.</li>
                                <li>
                                    To provide you with notices about your account/subscription,
                                    including expiration and renewal notices.
                                </li>
                                <li>
                                    To carry out our obligations and enforce our rights arising from
                                    any contracts entered into between you and us, including for
                                    billing and collection.
                                </li>
                                <li>
                                    To notify you about changes to our Services or any products or
                                    services we offer or provide though it.
                                </li>
                                <li>
                                    To allow you to participate in interactive features on our
                                    Services.
                                </li>
                                <li>
                                    In any other way we may describe when you provide the
                                    information.
                                </li>
                                <li>For any other purpose with your consent.</li>
                            </ul>

                            <h2 className="title is-5 has-text-grey">
                                Disclosure of Your Information
                            </h2>
                            <p>
                                We may disclose aggregated information about our users, and
                                information that does not identify any individual, without
                                restriction.
                            </p>
                            <p>
                                We may disclose personal information that we collect or you provide
                                as described in this privacy policy:
                            </p>
                            <ul className="has-list-checkmarks">
                                <li>
                                    To contractors, service providers, and other third parties we
                                    use to support our business and who are bound by contractual
                                    obligations to keep personal information confidential and use it
                                    only for the purposes for which we disclose it to them.
                                </li>
                                <li>
                                    To a buyer or other successor in the event of a merger,
                                    divestiture, restructuring, reorganization, dissolution, or
                                    other sale or transfer of some or all of Remoteforce's assets,
                                    whether as a going concern or as part of bankruptcy,
                                    liquidation, or similar proceeding, in which personal
                                    information held by Remoteforce about our Services users is
                                    among the assets transferred.
                                </li>
                                <li>To fulfill the purpose for which you provide it.</li>
                                <li>
                                    For any other purpose disclosed by us when you provide the
                                    information.
                                </li>
                                <li>With your consent.</li>
                            </ul>
                            <p>We may also disclose your personal information:</p>
                            <ul className="has-list-checkmarks">
                                <li>
                                    To comply with any court order, law, or legal process, including
                                    to respond to any government or regulatory request.
                                </li>
                                <li>
                                    To enforce or apply our other contractual agreements with you,
                                    including for billing and collection purposes.
                                </li>
                                <li>
                                    If we believe disclosure is necessary or appropriate to protect
                                    the rights, property, or safety of Remoteforce, our customers,
                                    or others.
                                </li>
                            </ul>

                            <h2 className="title is-5 has-text-grey">
                                Choices About How We Use and Disclose Your Information
                            </h2>
                            <p>
                                We strive to provide you with choices regarding the personal
                                information you provide to us. We have created mechanisms to provide
                                you with the following control over your information:
                            </p>
                            <ul className="has-list-checkmarks">
                                <li>
                                    Tracking Technologies and Advertising. You can set your browser
                                    to refuse all or some browser cookies, or to alert you when
                                    cookies are being sent. To learn how you can manage your Flash
                                    cookie settings, visit the Flash player settings page on Adobe's
                                    website. If you disable or refuse cookies, please note that some
                                    parts of this site may then be inaccessible or not function
                                    properly.
                                </li>
                                <li>
                                    Promotional Offers from the Company. If you do not wish to have
                                    your contact information used by the Company to promote our own
                                    or third parties' products or services, you can opt-out by
                                    sending us an email stating your request to{' '}
                                    <a href="mailto:support@remoteforce.com">
                                        support@remoteforce.com
                                    </a>
                                    . If we have sent you a promotional email, you may send us a
                                    return email asking to be omitted from future email
                                    distributions. This opt out does not apply to information
                                    provided to the Company as a result of a product purchase,
                                    warranty registration, product service experience or other
                                    transactions.
                                </li>
                            </ul>
                            <p>
                                We do not control third parties' collection or use of your
                                information to serve interest-based advertising. However these third
                                parties may provide you with ways to choose not to have your
                                information collected or used in this way. You can opt out of
                                receiving targeted ads from members of the Network Advertising
                                Initiative ("
                                <strong>
                                    <em>NAI</em>
                                </strong>
                                ") on the NAI's website.
                            </p>
                            <p>
                                Nevada residents who wish to exercise their sale opt-out rights
                                under Nevada Revised Statutes Chapter 603A may submit a request to
                                this designated address:{' '}
                                <a href="mailto:support@remoteforce.com">support@remoteforce.com</a>
                                . However, please know we do not currently sell data triggering that
                                statute's opt-out requirements.
                            </p>

                            <h2 className="title is-5 has-text-grey">
                                Accessing and Correcting Your Information
                            </h2>
                            <p>
                                You can review and change your personal information by logging into
                                our Services and visiting your account profile page.
                            </p>
                            <p>
                                You may also send us an email at{' '}
                                <a href="mailto:support@remoteforce.com">support@remoteforce.com</a>{' '}
                                to request access to, correct or delete any personal information
                                that you have provided to us. We cannot delete your personal
                                information except by also deleting your user account. We may not
                                accommodate a request to change information if we believe the change
                                would violate any law or legal requirement or cause the information
                                to be incorrect.
                            </p>

                            <h2 className="title is-5 has-text-grey">Data Security</h2>
                            <p>
                                We have implemented measures designed to secure your personal
                                information from accidental loss and from unauthorized access, use,
                                alteration, and disclosure. All information you provide to us is
                                stored on our secure servers behind firewalls. Any payment
                                transactions will be encrypted. All data in transit is protected by
                                Transport Layer Security (TLS). 2 Factor Authentication (2FA) is
                                required for all user accounts with access to sensitive data, and
                                all sensitive data is encrypted at rest using OpenSSL and the
                                AES-256-CBC cipher. Furthermore, all encrypted values are signed
                                with a message authentication code (MAC) to detect any modifications
                                to the encrypted string. User credentials such as passwords are
                                hashed using secure Bcrypt, Argon2 or Argon2id algorithms. Credit
                                card information is never stored in our databases.
                            </p>
                            <p>
                                The safety and security of your information also depends on you.
                                Where we have given you (or where you have chosen) a password for
                                access to certain parts of our Services, you are responsible for
                                keeping this password confidential. We ask you not to share your
                                password with anyone.
                            </p>
                            <p>
                                Unfortunately, the transmission of information via the internet is
                                not completely secure. Although we do our best to protect your
                                personal information, we cannot guarantee the security of your
                                personal information transmitted to our Website. Any transmission of
                                personal information is at your own risk. We are not responsible for
                                circumvention of any privacy settings or security measures contained
                                on our Services.
                            </p>

                            <h2 className="title is-5 has-text-grey">
                                Changes to Our Privacy Policy
                            </h2>
                            <p>
                                It is our policy to post any changes we make to our privacy policy
                                on this page with a notice that the privacy policy has been updated
                                on our website main pages. You are responsible for ensuring we have
                                an up-to-date active and deliverable email address for you, and for
                                periodically visiting our websites and this privacy policy to check
                                for any changes.
                            </p>
                            <h2 className="title is-5 has-text-grey">Contact Information</h2>
                            <p>
                                To ask questions or comment about this privacy policy and our
                                privacy practices, contact us at:{' '}
                                <a href="mailto:support@remoteforce.com">support@remoteforce.com</a>
                            </p>

                            <hr />
                            <p className="has-text-grey-light has-text-weight-bold">
                                Last Updated: May 13, 2020
                            </p>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        );
    }
}

const mapStateToProps = ({ nav, user }) => ({ nav, user });

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            saveUserDispatch,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);

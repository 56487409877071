import React from 'react';
import config from '../config';

export default () => (
    <section className="has-background-primary py-6" id="pricing">
        <div className="container">
            <h2 className="has-text-centered title is-3 mb-6 has-text-white">
                Bring Your Team Together
                <br />
                with SimpleStandups
            </h2>
            <div className="columns is-centered">
                <div className="column is-one-third">
                    <div className="pricing-plan has-background-white plan-card py-6 pl-6 pr-5">
                        <div className="plan-header has-text-centered">Team Basic</div>
                        <div className="plan-price title is-3 has-text-centered">
                            <span className="plan-price-amount">FREE</span>
                        </div>
                        <div className="plan-items mb-5">
                            <div className="plan-item">1 Standup</div>
                            <div className="plan-item has-text-weight-bold">
                                Up to 3 Participants Included!
                            </div>
                            <div className="plan-item">Unlimited Scheduling</div>
                            <div className="plan-item">Multiple Timezones</div>
                            <div className="plan-item">Limited Reporting</div>
                            <div className="plan-item">Basic Support</div>
                        </div>
                        <div className="plan-footer has-text-centered">
                            <a
                                href={config.slack_full_app_install_url}
                                className="button is-outlined is-medium is-rounded has-text-weight-bold"
                            >
                                <span className="icon mr-1">
                                    <i className="fab fa-slack fa-lg"></i>
                                </span>
                                <span>Start Free Trial</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="column is-one-third">
                    <div className="pricing-plan has-background-white plan-card popular py-6 pl-6 pr-5">
                        <div className="plan-header has-text-centered">
                            Team Unlimited
                            <span className="tag is-warning is-normal ml-1">Most Popular</span>
                        </div>
                        <div className="plan-price title is-3 has-text-centered">
                            <span className="plan-price-amount">
                                <span className="plan-price-currency">$</span>25
                            </span>
                            /month
                        </div>
                        <div className="plan-items mb-5">
                            <div className="plan-item">Unlimited Standups</div>
                            <div className="plan-item has-text-weight-bold">
                                Up to 25 Participants Included!
                            </div>
                            <div className="plan-item">Additional Participants at $1/mo.</div>
                            <div className="plan-item">Unlimited Scheduling</div>
                            <div className="plan-item">Multiple Timezones</div>
                            <div className="plan-item">Export Reports</div>
                            <div className="plan-item">Premium Support</div>
                        </div>
                        <div className="plan-footer has-text-centered">
                            <a
                                href={config.slack_full_app_install_url}
                                className="button is-primary is-medium is-rounded has-text-weight-bold"
                            >
                                <span className="icon mr-1">
                                    <i className="fab fa-slack fa-lg"></i>
                                </span>
                                <span>Start Free Trial</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="column is-one-third">
                    <div className="pricing-plan has-background-white plan-card py-6 pl-6 pr-5">
                        <div className="plan-header has-text-centered">Company Unlimited</div>
                        <div className="plan-price title is-3 has-text-centered">
                            <span className="plan-price-amount">
                                <span className="plan-price-currency">$</span>100
                            </span>
                            /month
                        </div>
                        <div className="plan-items mb-5">
                            <div className="plan-item">Unlimited Standups</div>
                            <div className="plan-item has-text-weight-bold">
                                Up to 125 Participants Included!
                            </div>
                            <div className="plan-item">Additional Participants at $1/mo.</div>
                            <div className="plan-item">Unlimited Scheduling</div>
                            <div className="plan-item">Multiple Timezones</div>
                            <div className="plan-item">Export Reports</div>
                            <div className="plan-item">Premium Support</div>
                        </div>
                        <div className="plan-footer has-text-centered">
                            <a
                                href={config.slack_full_app_install_url}
                                className="button is-danger is-outlined is-medium is-rounded has-text-weight-bold"
                            >
                                <span className="icon mb-1">
                                    <i className="fab fa-slack fa-lg"></i>
                                </span>
                                <span>Start Free Trial</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="has-text-centered has-text-white mt-5">
                <p>
                    Additional active participants are billed at $1 per month.
                    <br />
                    Rest assured that you'll only ever be billed for participants that you assign to
                    standups!
                </p>
                <p className="mt-4 is-size-7">
                    (For example, if you have 26 participants on the Team Unlimited plan -<br />
                    you'll be billed $26 per month.)
                </p>
            </div>
        </div>
    </section>
);

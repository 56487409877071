import React, { Component } from 'react';
import { Field, reduxForm, change, formValueSelector, FieldArray } from 'redux-form';
import {
    TextField,
    SingleCheckboxField,
    AsyncMultiSelect,
    SingleSelect,
    WeekInput,
} from './fields';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { SortableContainer } from 'react-sortable-hoc';
import array_move from 'array-move';
import { periods, weekOptions, timezones, scheduleTime } from '../../constants';
import _find from 'lodash/find';
import Quest from '../Quest';
import { Link } from 'react-router-dom';
import { fetchTemplateDispatch } from '../../reducers/Template';
import config from '../../config';

const persistDataPeriods = [
    {
        value: '1-week',
        label: '1 Week',
    },
    {
        value: '1-month',
        label: '1 Month',
    },
    {
        value: '3-month',
        label: '3 Month',
    },
    {
        value: '6-month',
        label: '6 Month',
    },
    {
        value: '1-year',
        label: '1 Year',
    },
    {
        value: 'forever',
        label: 'Forever',
    },
];

const SortableWrapper = SortableContainer(({ children }) => <ul>{children}</ul>);

const Questions = ({ onSortEnd }) => {
    return (
        <SortableWrapper useDragHandle onSortEnd={onSortEnd}>
            <FieldArray name="questions" component={Quest} />
        </SortableWrapper>
    );
};

export class SaveStandupForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            membersAutocompletePage: 1,
            channelsAutocompletePage: 1,
            showTemplates: false,
        };

        this.loadMembers = this.loadMembers.bind(this);
        this.loadChannels = this.loadChannels.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
    }

    componentDidMount() {
        this.props.fetchTemplateDispatch();
    }

    onSortEnd({ oldIndex, newIndex }) {
        const { state } = this.props;

        const selector = formValueSelector('saveStandupForm');

        this.props.change(
            'questions',
            array_move(selector(state, 'questions'), oldIndex, newIndex)
        );
    }

    // member selected
    async loadMembers(search, prevOptions) {
        const res = await axios.get(
            config.api_url +
                `/api/user/autocomplete?search=${encodeURIComponent(
                    search[0] === '@' ? search.substring(1) : search
                )}&page=${search ? 1 : this.state.membersAutocompletePage}&t=` +
                Math.floor(Date.now() / 1000),
            { withCredentials: true }
        );

        this.setState({
            membersAutocompletePage: this.state.membersAutocompletePage + 1,
        });

        return {
            options: [
                ...prevOptions,
                ...res.data.data.map((data) => ({
                    value: data.id,
                    label: `${data.name} (@${data.username})`,
                })),
            ],
            hasMore: !search && !!res.data.next_page_url,
        };
    }

    // channel selected
    async loadChannels(search, prevOptions) {
        const res = await axios.get(
            config.api_url +
                `/api/channel/autocomplete?search=${encodeURIComponent(
                    search[0] === '#' ? search.substring(1) : search
                )}&page=${search ? 1 : this.state.channelsAutocompletePage}&t=` +
                Math.floor(Date.now() / 1000),
            { withCredentials: true }
        );

        this.setState({
            channelsAutocompletePage: this.state.channelsAutocompletePage + 1,
        });

        return {
            options: [
                ...prevOptions,
                ...res.data.data.map((data) => ({ value: data.id, label: `#${data.name}` })),
            ],
            hasMore: !search && !!res.data.next_page_url,
        };
    }

    render() {
        const { error, submitting, handleSubmit, period } = this.props;
        const { state } = this.props;
        const selector = formValueSelector('saveStandupForm');
        const {
            template: { data },
            user: {
                team: { plan_id },
            },
        } = state;

        return (
            <div>
                <div className="columns">
                    <div className="column">
                        <h1 className="title is-5">Save {selector(state, 'name') || 'Standup'}</h1>
                    </div>
                    <div className="column">
                        <nav
                            className="breadcrumb has-arrow-separator is-right"
                            aria-label="breadcrumbs"
                        >
                            <ul>
                                <li>
                                    <Link to="/standups">Standups</Link>
                                </li>
                                <li className="is-active">
                                    <a href="#!" aria-current="page">
                                        Save {selector(state, 'name') || 'Standup'}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="form-padding plan-card plan-card--subtle has-background-grey-lighter">
                    {error && <div className="notification is-danger">{error}</div>}
                    <form onSubmit={handleSubmit}>
                        <Field name="id" type="hidden" component="input" />

                        <Field
                            name="name"
                            type="text"
                            component={TextField}
                            label="Standup Meeting Name"
                            placeholder="Daily Standup"
                        />

                        <Field
                            label="Broadcast Channels"
                            name="channels"
                            component={AsyncMultiSelect}
                            loadOptions={this.loadChannels}
                        />

                        <Field
                            label="Participants"
                            name="participants"
                            component={AsyncMultiSelect}
                            loadOptions={this.loadMembers}
                        />
                        <div className="columns">
                            <div className="column">
                                <Field
                                    name="period"
                                    component={SingleSelect}
                                    label="Period"
                                    options={periods}
                                />
                                <Field
                                    name="timezone"
                                    component={SingleSelect}
                                    label="Time Zone"
                                    options={timezones}
                                />
                                <Field
                                    name="schedule_time"
                                    component={SingleSelect}
                                    label="Schedule Time"
                                    options={scheduleTime}
                                />
                            </div>
                            <div className="column">
                                <label className="label">Choose Days</label>
                                {period &&
                                    Array.from({ length: period.display }).map((d, index) => (
                                        <Field
                                            key={index}
                                            name={`schedule.${index}`}
                                            component={WeekInput}
                                            options={weekOptions}
                                        />
                                    ))}
                            </div>
                        </div>

                        <Field
                            name="intro_message"
                            type="text"
                            component={TextField}
                            label="Intro Message"
                            placeholder="Time for {standup}! please answer following question when you have time"
                        />

                        {data.length > 0 && (
                            <div className="mt-5 mb-2">
                                <a
                                    href="#!"
                                    className="button is-danger is-rounded"
                                    style={{ marginBottom: 15 }}
                                    onClick={() =>
                                        this.setState({
                                            showTemplates: !this.state.showTemplates,
                                        })
                                    }
                                >
                                    <span className="icon mr-1">
                                        <i className="fad fa-clipboard-list-check fa-lg"></i>
                                    </span>
                                    Need Help with Questions?&nbsp;{' '}
                                    <strong>Select a Standup Template.</strong>
                                </a>
                            </div>
                        )}

                        <Questions onSortEnd={this.onSortEnd} />

                        <Field
                            name="outro_message"
                            type="text"
                            component={TextField}
                            label="Outro Message"
                            placeholder="All done :thumbs:"
                        />
                        <div className="columns">
                            {plan_id > 1 && (
                                <div className="column">
                                    <Field
                                        name="persist_data"
                                        component={SingleSelect}
                                        label="Persist Participant Response Data for:"
                                        options={persistDataPeriods}
                                    />
                                </div>
                            )}
                            <div className="column pt-6">
                                <Field
                                    name="active"
                                    type="text"
                                    component={SingleCheckboxField}
                                    label="Active"
                                />
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column">
                                <button
                                    type="submit"
                                    className={classNames(
                                        'button',
                                        'is-fullwidth',
                                        'is-primary',
                                        'is-rounded',
                                        'has-text-weight-bold',
                                        { 'is-loading': submitting }
                                    )}
                                >
                                    <span className="icon mr-1">
                                        <i className="fad fa-save fa-lg"></i>
                                    </span>
                                    Save
                                </button>
                            </div>
                            <div className="column">
                                <Link to="/standups" className="button is-fullwidth is-rounded">
                                    Cancel
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
                <div className={classNames('modal', { 'is-active': this.state.showTemplates })}>
                    <div className="modal-background"></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">Choose a Template</p>
                            <button
                                className="delete"
                                onClick={() =>
                                    this.setState({
                                        showTemplates: !this.state.showTemplates,
                                    })
                                }
                                aria-label="close"
                            ></button>
                        </header>
                        <section className="modal-card-body">
                            <p className="has-text-weight-bold mb-4">
                                Select a standup template to get started, and then customize it to
                                your needs:
                            </p>
                            <p className="has-text-grey mb-4">
                                Note: Selecting a template will override any questions that you
                                currently have inputted for this standup.
                            </p>
                            <div>
                                {data.map((tem) => (
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            if (
                                                window.confirm(
                                                    'Confirm that you want to use this template. Selecting this option will overwrite any questions that you currently have written for this standup.'
                                                )
                                            ) {
                                                this.props.change(
                                                    'questions',
                                                    tem.questions.map((t, index) => ({
                                                        question: t.question,
                                                        description: t.description,
                                                        sort: index + 1,
                                                    }))
                                                );
                                                this.setState({
                                                    showTemplates: !this.state.showTemplates,
                                                });
                                            }
                                        }}
                                        className="template-item has-background-light mb-5"
                                        key={tem.id}
                                    >
                                        <div className="template-header level">
                                            <div className="level-left">
                                                <div className="level-item">
                                                    <span className="icon mr-1">
                                                        <i className="fad fa-clipboard-list-check fa-lg"></i>
                                                    </span>
                                                </div>
                                                <div className="level-item">
                                                    <h2 className="title is-5 has-text-white">
                                                        {tem.name}
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className="level-right">
                                                <div className="level-item">
                                                    <button
                                                        type="button"
                                                        className="button is-white is-small is-rounded has-text-weight-bold"
                                                    >
                                                        <span className="icon mr-1">
                                                            <i className="fad fa-save fa-lg"></i>
                                                        </span>
                                                        Use Template
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="q-block px-3 pb-3">
                                            {tem.questions.map((question) => (
                                                <div className="ques" key={question.id}>
                                                    <h3 className="title is-6 has-text-weight-bold">
                                                        {question.question}
                                                    </h3>
                                                    <h4 className="subtitle has-text-grey is-6 has-text-weight-normal">
                                                        {question.description}
                                                    </h4>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </section>
                        <footer className="modal-card-foot"></footer>
                    </div>
                </div>
            </div>
        );
    }
}

const getInitialValue = (standup) => {
    if (standup.id) {
        return Object.assign({}, standup, {
            period: _find(periods, { value: standup.period }),
            timezone: _find(timezones, { value: standup.timezone }),
            schedule_time: _find(scheduleTime, { value: standup.schedule_time }),
            channels: standup.channels.map((channel) => ({
                value: channel.id,
                label: `#${channel.name}`,
            })),
            participants: standup.participants.map((participant) => ({
                value: participant.id,
                label: `${participant.name} (@${participant.username})`,
            })),
            persist_data: _find(persistDataPeriods, { value: standup.persist_data }),
        });
    }

    return {
        name: 'Daily Standup Meeting',
        period: periods[0],
        intro_message:
            'Time for *Standup Meeting*! please answer following question when you have time:',
        outro_message: 'All done :thumbsup:',
        schedule: [
            ['mon', 'tue', 'wed', 'thu', 'fri'],
            ['mon', 'tue', 'wed', 'thu', 'fri'],
            ['mon', 'tue', 'wed', 'thu', 'fri'],
            ['mon', 'tue', 'wed', 'thu', 'fri'],
        ],
        questions: [{}],
        timezone: timezones[0],
        schedule_time: _find(scheduleTime, { value: '05:00 PM' }),
        active: true,
        persist_data: _find(persistDataPeriods, { value: '1-year' }),
    };
};

const mapStateToProps = (state) => {
    const selector = formValueSelector('saveStandupForm');

    const initialValues = getInitialValue(state.standup.data);

    const period = selector(state, 'period') || initialValues.period;

    return { state, initialValues, period };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            change,
            fetchTemplateDispatch,
        },
        dispatch
    );

const validate = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = 'Name is required';
    }
    if (!values.channels || values.channels.length === 0) {
        errors.channels = 'Broadcast Channel is required';
    }
    if (!values.participants || values.participants.length === 0) {
        errors.participants = 'Participant is required';
    }
    if (!values.intro_message) {
        errors.intro_message = 'Intro Message is required';
    }
    if (!values.outro_message) {
        errors.outro_message = 'Outro Message is required';
    }
    if (!values.questions || values.questions.length === 0) {
        errors.questions = { _error: 'Atleast one question should be added' };
    } else {
        const questionErrors = [];
        values.questions.forEach((question, index) => {
            if (!question || !question.question) {
                questionErrors[index] = { question: 'Question is required' };
            }
        });
        errors.questions = questionErrors;
    }
    return errors;
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: 'saveStandupForm',
        enableReinitialize: true,
        validate,
    })(SaveStandupForm)
);

import axios from 'axios';
import _find from 'lodash/find';
import { periods } from '../constants';
import { SubmissionError } from 'redux-form';
import { NotificationManager } from 'react-notifications';
import { addNewStandupAction } from './StandupsActions';
import config from '../config';

export const SAVE_STANDUP = 'SAVE_STANDUP';
export const LOADING_STANDUP = 'LOADING_STANDUP';
export const LOADED_STANDUP = 'LOADED_STANDUP';
export const COLOR_PICKER_OPEN_STANDUP = 'COLOR_PICKER_OPEN_STANDUP';

export const saveStandupAction = (data) => ({
    type: SAVE_STANDUP,
    payload: data,
});

export const loadingStandupAction = () => ({
    type: LOADING_STANDUP,
});

export const loadedStandupAction = () => ({
    type: LOADED_STANDUP,
});

export const colorPickerOpenIndexAction = (data) => ({
    type: COLOR_PICKER_OPEN_STANDUP,
    payload: data,
});

export const saveStandupDispatch = (data, history, pathname) => async (dispatch) => {
    try {
        let period = _find(periods, { value: data.period.value });

        data.schedule = data.schedule.slice(0, period.display);

        const res = await axios.post(config.api_url + '/api/standup', data, {
            withCredentials: true,
        });

        dispatch(saveStandupAction(res.data));

        if (pathname !== `/standups/create/${res.data.id}`) {
            history.push(`/standups/create/${res.data.id}`);
        }

        dispatch(addNewStandupAction(res.data));

        NotificationManager.success('Standup saved successfully', 'Saved Successfully');
    } catch (e) {
        console.log('e', e);
        NotificationManager.error('Oops! something went wrong', 'Error');

        throw new SubmissionError(e.response.data.errors || e.response.data);
    }
};

export const fetchStandupDispatch = (id) => async (dispatch) => {
    dispatch(loadingStandupAction());

    try {
        const res = await axios.get(config.api_url + `/api/standup/${id}`, {
            withCredentials: true,
        });

        dispatch(saveStandupAction(res.data));
    } catch (e) {
        NotificationManager.error('Oops! something went wrong', 'Error');
    } finally {
        dispatch(loadedStandupAction());
    }
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { saveUserDispatch } from '../actions/AuthCompleteActions';
import AddToSlackBtn from './AddToSlackBtn';
import smoothscroll from 'smoothscroll-polyfill';
import heroImg from '../images/hero--checklist.svg';
import standupImg from '../images/n-standup.png';
import reportsImg from '../images/n-reports.png';
import ReactPlayer from 'react-player';
import introVideo from '../media/intro.mp4';
import Plans from './Plans';
import faqImg from '../images/hero--coworking.svg';
import Footer from './Footer';

const trialDays = 21;

const faqImgStyle = {
    width: '60%',
};

smoothscroll.polyfill();

export class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: 0,
        };

        this.faqs = [
            {
                question: 'What is a Participant?',
                answer: 'A Participant is a user that is subscribed to partake in Standups, Meetings and Check-ins.',
            },
            {
                question: 'How are Participants Billed?',
                answer: 'Only unique and active participants are counted for billing. For example, if you have two standups and both contain the same participant - this participant only counts as one.',
            },
            {
                question: 'Who can add a Participant to a Standup?',
                answer: 'Only an Administrator can add a Participant to a Standup. This is typically the manager for the team.',
            },
            {
                question: 'How many Standups can I schedule?',
                answer: 'You can schedule an unlimited number of Standups, Meetings and Check-ins. There are no restrictions on the number of standup meetings you create.',
            },
            {
                question:
                    'As a Participant, what if I don’t have anything to answer to a question?',
                answer: "Simply don't reply. That's it!",
            },
            {
                question: 'Where can I find Standup reports?',
                answer: 'Reports are accessible by the Administrator when logged into SimpleStandups. Additionally, each report may be posted to the Slack channel of your choice. (We support both private and public Slack channels.)',
            },
            {
                question: 'Is my data secure?',
                answer: 'Yes! All data is securely held within Amazon Web Services (North American regions) using industry best practices, including TLS (Transport Layer Security).',
            },
            {
                question: 'Is my billing information safe?',
                answer: 'Yes! All information is sent directly to our billing partner, Stripe. Your credit card information is never stored directly by SimpleStandups.',
            },
            {
                question: 'Are you GDPR compliant?',
                answer: 'Yes! For our European customers, please reach out to our support team to receive an executable Data Processing Agreement if required.',
            },
        ];
    }

    componentDidMount() {
        const { user } = this.props;

        if (!user.id && !user.loaded) {
            this.props.saveUserDispatch();
        }
    }

    render() {
        return (
            <div className="home-page">
                <section className="py-6 has-bg-white">
                    <div className="container">
                        <div className="section">
                            <div className="columns is-desktop is-vcentered">
                                <div className="column">
                                    <h1 className="title is-2">
                                        Simple Bot For Running Standup Meetings on Slack!
                                    </h1>
                                    <h2 className="subtitle mt-2">
                                        Hassle free standup meetings, surveys, questionnaires and
                                        more for small and large teams.
                                    </h2>
                                    <AddToSlackBtn />
                                    <div className="mt-2 has-text-grey">
                                        <i>Start your {trialDays}-day free trial now!</i>
                                    </div>
                                </div>
                                <div className="column">
                                    <img src={heroImg} alt="" className="mt-5" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="has-background-primary">
                    <div className="container">
                        <div className="section">
                            <div className="columns is-desktop is-vcentered">
                                <div className="column">
                                    <h3 className="title is-3 has-text-white">
                                        Effortlessly Create Standup Meetings
                                    </h3>
                                    <p className="subtitle has-text-white">
                                        Create unlimited standups in a single click. Start standup
                                        at participants timezone or any other specific timezone.
                                    </p>
                                </div>
                                <div className="column">
                                    <img src={standupImg} alt="Standup Meeting" className="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="has-background-dark">
                    <div className="container">
                        <div className="section">
                            <div className="columns is-desktop is-vcentered">
                                <div className="column">
                                    <img
                                        src={reportsImg}
                                        alt="Real-time Reports"
                                        className="image"
                                    />
                                </div>
                                <div className="column">
                                    <h3 className="title is-3 has-text-white">Real-time Reports</h3>
                                    <p className="subtitle has-text-white">
                                        Get daily insights in your dashboard. Monitor and optimize
                                        your teams performance.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="has-background-primary">
                    <div className="container">
                        <div className="section">
                            <div className="columns is-desktop is-vcentered">
                                <div className="column is-half">
                                    <p className="is-6 is-uppercase subtitle has-text-white">
                                        How it Works
                                    </p>
                                    <h2 className="title is-3 has-text-white mb-6">
                                        See It In Action
                                    </h2>
                                    <p className="pb-2 has-text-white subtitle">
                                        Create seamless standup meetings and collect reports in
                                        real-time. Track your teams progress and make them more
                                        productive with Simple Standups!
                                    </p>
                                    <AddToSlackBtn />
                                </div>
                                <div className="column is-half">
                                    <div className="player-wrapper">
                                        <ReactPlayer
                                            url={introVideo}
                                            className="react-player"
                                            width="100%"
                                            height="100%"
                                            playing
                                            loop
                                            volume={0}
                                            muted
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="hero has-background-white mt-6" id="features">
                    <div className="container">
                        <div className="section">
                            <h3 className="title is-3 has-text-centered mb-0">Features</h3>
                            <p className="has-text-centered secondary mb-6 text-secondary">
                                Making standup meetings work for your team...
                            </p>
                            <div className="columns is-multiline">
                                <div className="column is-3 mb-3 has-text-centered">
                                    <span className="icon has-text-primary">
                                        <i className="fad fa-th-list fa-3x"></i>
                                    </span>
                                    <h4 className="has-text-dark has-text-weight-bold my-2">
                                        Unlimited Standups
                                    </h4>
                                    <p className="text-secondary">
                                        Create as many standups as you want and add as many
                                        participants based on plans you choose.
                                    </p>
                                </div>
                                <div className="column is-3 mb-3 has-text-centered">
                                    <span className="icon has-text-primary">
                                        <i className="fad fa-clipboard-list-check fa-3x"></i>
                                    </span>
                                    <h4 className="has-text-dark has-text-weight-bold my-2">
                                        Unlimited Custom Questions
                                    </h4>
                                    <p className="text-secondary">
                                        Ask custom questions per standup meeting, questionnaire or
                                        survey.
                                    </p>
                                </div>
                                <div className="column is-3 mb-3 has-text-centered">
                                    <span className="icon has-text-primary">
                                        <i className="fad fa-calendar-alt fa-3x"></i>
                                    </span>
                                    <h4 className="has-text-dark has-text-weight-bold my-2">
                                        Custom Schedules
                                    </h4>
                                    <p className="text-secondary">
                                        Schedule meetings daily, weekly or monthly. Run meetings on
                                        your schedule.
                                    </p>
                                </div>
                                <div className="column is-3 mb-3 has-text-centered">
                                    <span className="icon has-text-primary">
                                        <i className="fad fa-clock fa-3x"></i>
                                    </span>
                                    <h4 className="has-text-dark has-text-weight-bold my-2">
                                        Multiple Timezones
                                    </h4>
                                    <p className="text-secondary">
                                        Conduct standups in participant's timezone or choose from
                                        any available timezone.
                                    </p>
                                </div>
                                <div className="column is-3 mb-3 has-text-centered">
                                    <span className="icon has-text-primary">
                                        <i className="fad fa-bell-on fa-3x"></i>
                                    </span>
                                    <h4 className="has-text-dark has-text-weight-bold my-2">
                                        Reminders
                                    </h4>
                                    <p className="text-secondary">
                                        Automated reminder for participants to join standups at
                                        scheduled times.
                                    </p>
                                </div>
                                <div className="column is-3 mb-3 has-text-centered">
                                    <span className="icon has-text-primary">
                                        <i className="fad fa-code-branch fa-3x"></i>
                                    </span>
                                    <h4 className="has-text-dark has-text-weight-bold my-2">
                                        Report to Multiple Channels
                                    </h4>
                                    <p className="text-secondary">
                                        Optionally post participant responeses to multiple Slack
                                        channels in real-time.
                                    </p>
                                </div>
                                <div className="column is-3 mb-3 has-text-centered">
                                    <span className="icon has-text-primary">
                                        <i className="fad fa-file-chart-line fa-3x"></i>
                                    </span>
                                    <h4 className="has-text-dark has-text-weight-bold my-2">
                                        Reports Dashboard
                                    </h4>
                                    <p className="text-secondary">
                                        View reports that shows answers for each participant.
                                    </p>
                                </div>
                                <div className="column is-3 mb-3 has-text-centered">
                                    <span className="icon has-text-primary">
                                        <i className="fad fa-envelope-open-text fa-3x"></i>
                                    </span>
                                    <h4 className="has-text-dark has-text-weight-bold my-2">
                                        Email Reports
                                    </h4>
                                    <p className="text-secondary">
                                        Get a CSV of the report data sent directly to your email
                                        address in a single click.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Plans />

                <section className="hero has-background-white" id="faq">
                    <div className="container">
                        <div className="section">
                            <div className="has-text-centered mb-4">
                                <img src={faqImg} alt="" style={faqImgStyle} />
                            </div>

                            <h2 className="title is-3 has-text-centered">F.A.Q.</h2>

                            {this.faqs.map((faq, index) => (
                                <div className="has-background-light px-4 py-4 mb-4" key={index}>
                                    <div className="is-size-5 has-text-weight-bold has-text-danger">
                                        {faq.question}
                                    </div>
                                    <div className="is-size-6 has-text-grey-dark">{faq.answer}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = ({ nav, user }) => ({ nav, user });

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            saveUserDispatch,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Home);

import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import config from '../config';

export const FETCH_REPORTS = 'FETCH_REPORTS';
export const RESET_REPORTS = 'RESET_REPORTS';
export const TOGGLE_EXPORT_MODAL_REPORTS = 'TOGGLE_EXPORT_MODAL_REPORTS';

export const fetchReportsAction = (data) => ({
    type: FETCH_REPORTS,
    payload: data,
});

export const resetReportAction = () => ({
    type: RESET_REPORTS,
});

export const toggleExportModalReportAction = () => ({
    type: TOGGLE_EXPORT_MODAL_REPORTS,
});

// dispatch
export const fetchReportsDispatch = (id, page) => async (dispatch) => {
    try {
        const res = await axios.get(config.api_url + `/api/standup/${id}/reports?page=${page}`, {
            withCredentials: true,
        });

        return dispatch(fetchReportsAction(res.data));
    } catch (e) {
        NotificationManager.error('Oops! something went wrong', 'Error');
    }
};

export const exportReportsDispatch = (id, data) => async (dispatch) => {
    try {
        await axios.post(config.api_url + `/api/standup/${id}/export`, data, {
            withCredentials: true,
        });

        dispatch(toggleExportModalReportAction());
    } catch (e) {
        NotificationManager.error('Oops! something went wrong', 'Error');
    }
};

import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';
import { StartDate, EndDate, CheckboxField } from './fields';
import classNames from 'classnames';
import moment from 'moment/moment';

export class ExportReportsForm extends Component {
    render() {
        const { handleSubmit, submitting, state } = this.props;
        const selector = formValueSelector('ExportReportsForm');

        return (
            <form onSubmit={handleSubmit}>
                <div className="columns">
                    <div className="column">
                        <Field
                            name="start_date"
                            component={StartDate}
                            endDate={selector(state, 'end_date')}
                        />
                    </div>
                    <div className="column">
                        <Field
                            name="end_date"
                            component={EndDate}
                            startDate={selector(state, 'start_date')}
                        />
                    </div>
                </div>
                <div className="my-1">
                    <Field
                        name="optin"
                        component={CheckboxField}
                        label="Opt-in to Receive Emails"
                    />
                </div>
                <button
                    type="submit"
                    className={classNames('button', 'is-fullwidth', 'is-rounded', {
                        'is-loading': submitting,
                    })}
                >
                    <span className="icon mr-1">
                        <i className="far fa-envelope"></i>
                    </span>
                    Email Report
                </button>
            </form>
        );
    }
}

const validate = (values) => {
    const errors = {};
    if (!values.start_date) {
        errors.start_date = 'Required';
    }
    if (!values.end_date) {
        errors.end_date = 'Required';
    }
    if (!values.optin) {
        errors.optin = 'Please optin to receive email';
    }

    return errors;
};

const mapStateToProps = (state) => ({
    state,
    initialValues: {
        start_date: moment().subtract(30, 'd').format('MM/DD/YYYY'),
        end_date: moment().format('MM/DD/YYYY'),
    },
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            change,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: 'ExportReportsForm',
        validate,
    })(ExportReportsForm)
);

import React from 'react';
import ContentLoader from 'react-content-loader';

export default (props) => (
    <ContentLoader
        height={160}
        width={400}
        speed={2}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="12" y="11" rx="3" ry="3" width="350" height="8.06" />
        <rect x="12" y="36.18" rx="3" ry="3" width="380" height="8.06" />
        <rect x="12" y="61.36" rx="3" ry="3" width="201" height="8.06" />
    </ContentLoader>
);

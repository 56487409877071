export const ERROR = 'ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';

export const errorAction = (data) => ({
    type: ERROR,
    payload: data,
});

export const hideErrorAction = () => ({
    type: HIDE_ERROR,
});

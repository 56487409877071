import React, { Component } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleNavAction } from '../../actions/NavActions';
import classNames from 'classnames';
import { logoutUsersDispatch } from '../../actions/AuthCompleteActions';
import SignInWithSlackBtn from '../SignInWithSlackBtn';
import logoFull from '../../images/logo--full--simplestandups.png';
import config from '../../config';

const billingUrl = config.api_url + '/settings/billing';
const logoutUrl = config.api_url + '/logout';

class Nav extends Component {
    constructor(props) {
        super(props);

        this._toggleNav = this._toggleNav.bind(this);
        this._logout = this._logout.bind(this);
    }

    _toggleNav() {
        this.props.toggleNavAction();
    }

    _logout() {
        this.props.logoutUsersDispatch(this.props.history);
    }

    openBeacon() {
        window.Beacon('open');
    }

    render() {
        const {
            nav: { isOpen },
            user,
        } = this.props;

        return (
            <nav className="navbar is-transparent is-spaced">
                <div className="container">
                    <div className="navbar-brand">
                        {!user.id && (
                            <NavLink className="navbar-item" to="/">
                                <img src={logoFull} alt="Simple Standups" height={'28'} />
                            </NavLink>
                        )}
                        {user.id && (
                            <NavLink className="navbar-item" to="/standups">
                                <img src={logoFull} alt="Simple Standups" height={'28'} />
                            </NavLink>
                        )}
                        <a
                            href="#!"
                            role="button"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onClick={this._toggleNav}
                            className="navbar-burger burger is-hidden-desktop"
                            aria-label="menu"
                            aria-expanded="false"
                            data-target="navbarElements"
                        >
                            {isOpen ? (
                                <svg className="icon-svg" viewBox="0 0 24 24">
                                    <path
                                        fill="#515151"
                                        d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
                                    />
                                </svg>
                            ) : (
                                <svg className="icon-svg" viewBox="0 0 24 24">
                                    <path
                                        fill="#515151"
                                        d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
                                    />
                                </svg>
                            )}
                        </a>
                    </div>

                    <div
                        id="navbarElements"
                        className={classNames('navbar-menu', { 'is-active': isOpen })}
                    >
                        <div className="navbar-start">
                            {/*{user.id && (*/}
                            {/*    <a href={dashboardUrl} className="navbar-item">*/}
                            {/*        Dashboard*/}
                            {/*    </a>*/}
                            {/*)}*/}
                            {user.id && (
                                <NavLink
                                    activeClassName="is-active"
                                    to="/standups"
                                    className="navbar-item"
                                >
                                    Standups
                                </NavLink>
                            )}
                            {user.id && (
                                <a href={billingUrl} className="navbar-item">
                                    Settings
                                </a>
                            )}
                        </div>

                        <div className="navbar-end">
                            {user.id && (
                                <div className="navbar-item">
                                    <div className="buttons">
                                        <Link
                                            to="/standups/create"
                                            className="button is-primary is-rounded"
                                        >
                                            <span className="icon">
                                                <i className="far fa-calendar-plus"></i>
                                            </span>
                                            <strong>Create Standup</strong>
                                        </Link>
                                    </div>
                                </div>
                            )}
                            {user.id && (
                                <div className="navbar-item has-dropdown is-hoverable">
                                    <a href="#!" className="navbar-link">
                                        <figure className="image is-24x24 mr-1">
                                            <img
                                                src={user.avatar}
                                                width="24"
                                                className="is-24x24 is-rounded"
                                                alt=""
                                            />
                                        </figure>
                                        {user.name}
                                    </a>
                                    <div className="navbar-dropdown is-boxed">
                                        <a
                                            href="#!"
                                            onClick={this.openBeacon}
                                            className="navbar-item"
                                        >
                                            Help &amp; Support
                                        </a>
                                        <hr className="navbar-divider" />
                                        <a href={logoutUrl} className="navbar-item">
                                            Log Out
                                        </a>
                                    </div>
                                </div>
                            )}
                            {!user.id && (
                                <div className="navbar-item">
                                    <div className="buttons">
                                        <SignInWithSlackBtn />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = ({ nav, user }) => ({ nav, user });

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            toggleNavAction,
            logoutUsersDispatch,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));

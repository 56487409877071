import { combineReducers } from 'redux';
import StandupsReducer from './StandupsReducer';
import ErrorReducer from './ErrorReducer';
import StandupReducer from './StandupReducer';
import UserReducer from './UserReducer';
import { reducer as formReducer } from 'redux-form';
import ReportsReducer from './ReportsReducer';
import NavReducer from './NavReducer';
import BillingReducer from './BillingReducer';
import Template from './Template';

export default combineReducers({
    standups: StandupsReducer,
    error: ErrorReducer,
    standup: StandupReducer,
    user: UserReducer,
    reports: ReportsReducer,
    nav: NavReducer,
    billing: BillingReducer,
    form: formReducer,
    template: Template,
});

import React from 'react';
import { Link } from 'react-router-dom';

export default () => (
    <section className="section plan-card plan-card--subtle has-background-grey-lighter">
        <header className="bd-index-header">
            <h4 className="title is-4 has-text-centered">
                Get Started By Creating New Standup Meeting.
            </h4>
        </header>

        <div className="hero-buttons">
            <Link
                to="/standups/create"
                className="button is-primary is-rounded is-medium has-text-weight-bold"
            >
                <span className="icon">
                    <i className="far fa-calendar-plus"></i>
                </span>
                <span>Create New Standup</span>
            </Link>
        </div>
    </section>
);
